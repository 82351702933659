import React, { useState } from "react";
import HomeIcon from "./../assets/Svg/HomeIcon";
import OrderIcon from "./../assets/Svg/OrderIcon";
import {
  MdOutlineKeyboardArrowRight,
  MdKeyboardArrowDown,
} from "react-icons/md";
import RoundIcon from "./../assets/Svg/RoundIcon";
import ResturantIcon from "./../assets/Svg/ResturantIcon";
import BrandIcon from "./../assets/Svg/BrandIcon";
import DocumentIcon from "./../assets/Svg/DocumentIcon";
import CancelIcon from "./../assets/Svg/CancelIcon";
import PromocodeIcon from "./../assets/Svg/PromocodeIcon";
import BannerIcon from "./../assets/Svg/BannerIcon";
import UserIcon from "./../assets/Svg/UserIcon";
import CategoryIcon from "./../assets/Svg/CategoryIcon";
import UnitIcon from "./../assets/Svg/UnitIcon";
import TagIcon from "./../assets/Svg/TagIcon";
import CusinesIcon from "./../assets/Svg/CusinesIcon";
import AddonsIcon from "./../assets/Svg/AddonsIcon";
import PayoutIcon from "./../assets/Svg/PayoutIcon";
import FoodQuantityIcon from "./../assets/Svg/FoodQuantityIcon";
import ContentIcon from "./../assets/Svg/ContentIcon";
import RolesIcon from "./../assets/Svg/RolesIcon";
import ReportIcon from "./../assets/Svg/ReportIcon";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "./../assets/images/logo3.png";
import Driver from "./../assets/Svg/Driver";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import "../fonts/font.css";

const Sidebar = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const currentPath = location.pathname;

  const [order, setOrder] = useState(false);
  const [resturant, setResturant] = useState(false);
  const [Account, setAccount] = useState(false);
  const [driver, setDriver] = useState(false);
  const [document, setDocument] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [promocodes, setPromocodes] = useState(false);
  const [banner, setBanner] = useState(false);
  const [category, setCategory] = useState(false);
  const [unit, setUnit] = useState(false);
  const [tag, setTag] = useState(false);
  const [cuisines, setCuisines] = useState(false);
  const [adons, setAdons] = useState(false);
  const [pay, setPay] = useState(false);
  const [foodq, setFoodq] = useState(false);
  const [content, setContent] = useState(false);
  const [roles, setRoles] = useState(false);
  const [reports, setReports] = useState(false);
  const [calender, setCalender] = useState(false);
  const [menu, setMenu] = useState(false);

  return (
    <div style={{ fontFamily: "Metropolis", fontSize: 50, color: "#0e0593" }}>
      <div className=" flex justify-center pt-5 w-full">
        <p style={{ fontFamily: "Metropolis", fontSize: 50, color: "#0e0593" }}>
          FESSTE
        </p>
      </div>

      <div className=" px-6 py-5 overflow-y-auto  max-h-[640px]">
        <div className="grid gap-y-5 cursor-pointer ">
          <div
            className={`flex gap-x-3 py-2 px-1 pt-2${
              currentPath === "/dashboard" ? "" : ""
            }`}
            onClick={() => navigate("/dashboard")}
          >
            <HomeIcon />{" "}
            <p className="text-black text-sm font-semibold">Dashboard </p>
          </div>

          <div
            onClick={() => setMenu(!menu)}
            className="flex gap-x-3 items-center"
          >
            <ContentIcon />{" "}
            <p className="text-black text-sm font-semibold">Menu</p>{" "}
          </div>
          {menu && (
            <>
              <div
                className="flex gap-x-3 items-center "
                onClick={() => navigate("/resturantmenu")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  Resturant Menu{" "}
                </p>
              </div>
              <div
                className="flex gap-x-3 items-center "
                onClick={() => navigate("/combolist")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">Menu List </p>
              </div>
              <div
                className="flex gap-x-3 items-center "
                onClick={() => navigate("/menu")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">Combos </p>
              </div>
            </>
          )}

          <div
            onClick={() => setCalender(!calender)}
            className="flex gap-x-3 items-center"
          >
            <EditCalendarIcon />{" "}
            <p className="text-black text-sm font-semibold">Calenders</p>{" "}
          </div>
          {calender && (
            <>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/calender")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  Set Your Calender
                </p>
              </div>
            </>
          )}

          <div
            onClick={() => setOrder(!order)}
            className="flex gap-x-3 items-center"
          >
            <OrderIcon />{" "}
            <p className=" text-black text-sm font-semibold ">
              Order Management
            </p>{" "}
          </div>

          {order && (
            <>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/neworder")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold"> New Order</p>
              </div>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/delayedorder")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  {" "}
                  Delayed Order
                </p>
              </div>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/fullfillorder")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  {" "}
                  Fullfill Order
                </p>
              </div>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/cancelorder")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  {" "}
                  Cancel Order
                </p>
              </div>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/refundOrder")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  {" "}
                  Refund Order
                </p>
              </div>
            </>
          )}
          <div
            onClick={() => setPay(!pay)}
            className="flex gap-x-3 items-center"
          >
            <PayoutIcon />{" "}
            <p className="text-black text-sm font-semibold">Payment History</p>{" "}
          </div>
          {pay && (
            <>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/overview")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">Overview</p>
              </div>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/account")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  Bank Account Details
                </p>
              </div>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/invoice")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">Invoices</p>
              </div>
            </>
          )}
          <div
            onClick={() => setContent(!content)}
            className="flex gap-x-3 items-center"
          >
            <ContentIcon />{" "}
            <p className="text-black text-sm font-semibold">Quotation</p>{" "}
          </div>
          {content && (
            <>
              <div
                className="flex gap-x-3 items-center "
                onClick={() => navigate("/quortation")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">Quotation </p>
              </div>
              <div
                className="flex gap-x-3 items-center "
                onClick={() => navigate("/list")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">List </p>
              </div>
            </>
          )}

          {driver && (
            <>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/deliverypartnerlist")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold ">
                  Delivery Partner List
                </p>
              </div>

              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/pendingpartnerlist")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  Pending Partner List
                </p>
              </div>
            </>
          )}

          <div
            onClick={() => setPromocodes(!promocodes)}
            className="flex gap-x-3 items-center"
          >
            <PromocodeIcon />{" "}
            <p className="text-black text-sm font-semibold">Promocodes</p>{" "}
          </div>
          {promocodes && (
            <>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/promocodelist")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  {" "}
                  Promocodes List
                </p>
              </div>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/addpromocode")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  {" "}
                  Add Promocodes
                </p>
              </div>
            </>
          )}

          <div
            onClick={() => setReports(!reports)}
            className="flex gap-x-3 items-center"
          >
            <ReportIcon />{" "}
            <p className="text-black text-sm font-semibold">Reports</p>
          </div>
          {reports && (
            <>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/resturantreport")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  Resturant Reports
                </p>
              </div>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/deliveryreport")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  Delivery Partner Reports
                </p>
              </div>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/orderreport")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  Order Reports
                </p>
              </div>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/topusersreport")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  {" "}
                  Top Users Reports
                </p>
              </div>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/profitlossreport")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  Profit Loss Reports
                </p>
              </div>
            </>
          )}
          <div
            onClick={() => setBanner(!banner)}
            className="flex gap-x-3 items-center"
          >
            <BannerIcon />{" "}
            <p className="text-black text-sm font-semibold">
              Restaurant Banner
            </p>{" "}
          </div>
          {banner && (
            <>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/resturantbannerlist")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  {" "}
                  Resturant Banner List
                </p>
              </div>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/addresturantbanner")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold">
                  {" "}
                  Add Resturant Banner
                </p>
              </div>
            </>
          )}

          <div
            onClick={() => setResturant(!resturant)}
            className="flex gap-x-3 items-center"
          >
            <ResturantIcon />{" "}
            <p className="text-black text-sm font-semibold">Profiles</p>{" "}
          </div>
          {resturant && (
            <>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/profile")}
              >
                <RoundIcon />{" "}
                <p className="text-black text-sm font-semibold"> Profile</p>
              </div>
              <div
                className="flex gap-x-3 items-center"
                onClick={() => navigate("/activeresturant")}
              >
                <RoundIcon />
                <p className="text-black text-sm font-semibold">
                  {" "}
                  Document Management
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
