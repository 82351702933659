import React, { useState } from "react";
import Layout from "../../../dashbord/Layout";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styled from "styled-components";
import Modal from "react-modal";
import { Button } from "antd";
import '../../../fonts/font.css'

const StyledCalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Metropolis
`;

const ModalContent = styled.div`
  
`;

const Calender = () => {
  const [date, setDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDateChange = (newDate) => {
    setDate(newDate);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <StyledCalendarContainer>
        
        <div className="w-full max-w-lg bg-[#f6f6f6] rounded-lg shadow-md p-6">
          <Calendar
            onChange={handleDateChange}
            value={date}
            className="w-full rounded-lg border border-gray-300 mb-6"
            calendarType="US" // Change calendarType to US
            locale="en-US"
            showFixedNumberOfWeeks
            prev2Label={null}
            next2Label={null}
            tileClassName={({ date, view }) => {
              if (view === 'month' && date.getDate() === new Date().getDate()) {
                return 'today';
              }
            }}
          />
          <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
            <h1 className="text-lg font-bold">Date</h1>
            <h1 className="text-lg font-bold">Reasons</h1>
          </div>
          <div className="flex justify-between items-center p-4 bg-white rounded-lg border border-gray-200">
            <div className="flex items-center">
              <h1 className="mr-4">20/01/2024</h1>
              <p>Booked for Gezte customers</p>
            </div>
            <div className="flex items-center space-x-4">
              <Button >Edit</Button>
              <Button>Delete</Button>
            </div>
          </div>
        </div>
      </StyledCalendarContainer>
      {/* Modal for input */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Input Modal"
        className="bg-white shadow-sm border-2 border-#0e0593 h-70 w-96 mx-auto mt-40 p-6 rounded-md"
      >
        <div className="flex flex-col space-y-4">
          <h2 className="text-xl font-semibold">Select Your Reasons</h2>
          <div className="flex items-center space-x-4">
            <input type="checkbox" />
            <label>Booked for other customers</label>
          </div>
          <div className="flex items-center space-x-4">
            <input type="checkbox" />
            <label>Booked for Gezte customers</label>
          </div>
          <Button type="primary" onClick={closeModal}>Submit</Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default Calender;
