import React, { useState } from "react";
import Layout from "./../../../dashbord/Layout";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  const [dishes, setDishes] = useState([
    { id: 1, name: "Vegetarian Pasta", type: "Veg" },
    { id: 2, name: "Chicken Curry", type: "Non-Veg" },
    { id: 3, name: "Chocolate Cake", type: "Dessert" },
    { id: 4, name: "Margherita Pizza", type: "Veg" },
    { id: 5, name: "Chicken Burger", type: "Non-Veg" },
    { id: 6, name: "Vanilla Ice Cream", type: "Dessert" },
    { id: 7, name: "Vegetarian Pasta", type: "Veg" },
    { id: 8, name: "Chicken Curry", type: "Non-Veg" },
    { id: 9, name: "Chocolate Cake", type: "Dessert" },
    { id: 10, name: "Margherita Pizza", type: "Veg" },
    { id: 11, name: "Chicken Burger", type: "Non-Veg" },
    { id: 12, name: "Vanilla Ice Cream", type: "Dessert" },
  ]);
  const navigate = useNavigate();

  const handleCheckboxChange = (id) => {
    setDishes((prevDishes) =>
      prevDishes.map((dish) =>
        dish.id === id ? { ...dish, selected: !dish.selected } : dish
      )
    );
  };

  const renderDishCheckbox = (dish) => (
    <div
      key={dish.id}
      className="p-4 border rounded-lg bg-white shadow-md hover:shadow-lg transition duration-300"
    >
      <label className="flex items-center space-x-2">
        <input
          type="checkbox"
          checked={dish.selected || false}
          onChange={() => handleCheckboxChange(dish.id)}
          className="form-checkbox h-5 w-5 text-indigo-600"
        />
        <span className="text-lg">{dish.name}</span>
      </label>
    </div>
  );

  const renderDishList = (dishType) => (
    <div key={dishType} className="col mb-8">
      <h2 className="text-2xl font-bold mb-4">{dishType}</h2>
      {dishes.filter((dish) => dish.type === dishType).map(renderDishCheckbox)}
    </div>
  );

  return (
    <Layout>
      <div className="p-8 border shadow-md bg-white rounded-lg">
        <h1 className="text-4xl font-semibold mb-8 text-indigo-900">
          CREATE YOUR COMBO
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <label className="text-lg block mb-2">Combo Name</label>
            <input
              type="text"
              className="input"
              placeholder="Enter Combo Name"
            />
          </div>
          <div>
            <label className="text-lg block mb-2">Price</label>
            <input
              type="text"
              className="input"
              placeholder="Enter Total Price"
            />
          </div>
          <div>
            <label className="text-lg block mb-2">Cuisine</label>
            <select className="input">
              <option>Select Cuisine</option>
              <option>Veg</option>
              <option>Non-Veg</option>
            </select>
          </div>
          <div>
            <label className="text-lg block mb-2">Unit</label>
            <select className="input">
              <option>Select Unit</option>
              <option>1</option>
              <option>2</option>
            </select>
          </div>
          <div>
            <label className="text-lg block mb-2">Category</label>
            <select className="input">
              <option>Select Category</option>
              <option>Main Course</option>
              <option>Appetizer</option>
              <option>Dessert</option>
            </select>
          </div>
          <div className="flex flex-col justify-between">
            <label className="text-lg block mb-2">Select Type</label>
            <div className="flex items-center space-x-4">
              <label className="flex items-center space-x-2 cursor-pointer">
                <input type="radio" className="mt-2" />
                <span className="text-indigo-700">Veg</span>
              </label>
              <label className="flex items-center space-x-2 cursor-pointer">
                <input type="radio" className="mt-2" />
                <span className="text-indigo-700">Non-Veg</span>
              </label>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <h1 className="text-lg font-medium mt-5 mb-4 text-indigo-700">
            Select Menu
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {["Veg", "Non-Veg", "Dessert"].map(renderDishList)}
          </div>
        </div>
        <div className="mt-8">
          <Button className="bg-fuchsia-900 text-white hover:bg-indigo-800">
            Submit
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default Menu;
