import React, { useState } from "react";
import Layout from "./../../../dashbord/Layout";
import { useNavigate } from "react-router-dom";
import EyeIcon from "./../../../assets/Svg/EyeIcon";
import Table from "./../../../dashbord/Table";
import { Dropdown, Menu } from "antd";
import { CiMenuKebab } from "react-icons/ci";
import EditIcon from "./../../../assets/Svg/EditIcon";
import { FaPlus } from "react-icons/fa6";
import DeleteIcon from "./../../../assets/Svg/DeleteIcon";
import { CiSearch } from "react-icons/ci";
import List from "./../../../assets/Svg/List";

const StaffList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [viewData, setViewData] = useState(null);

  const showTotal = (pages, range) => {
    const typeData = [
      {
        id: "1",
        label: "5",
      },
      {
        id: "2",
        label: "15",
      },
      {
        id: "3",
        label: "25",
      },
      {
        id: "4",
        label: "35",
      },
    ];
  };

  const tableObj = [
    {
      srno: "1",
      name: "Salem RR -ECR",
      resturant:"Pizza.com - Italia",
      rolename:"Admin Role",
      roletype:"Admin",
     
    },
    {
      srno: "2",
      name: "Salem RR -ECR",
      resturant:"Pizza.com - Italia",
      rolename:"Admin Role",
      roletype:"Admin",
    },
    {
      srno: "3",
      name: "Salem RR -ECR",
      resturant:"Pizza.com - Italia",
      rolename:"Admin Role",
      roletype:"Admin",
    },
    {
      srno: "4",
      name: "Salem RR -ECR",
      resturant:"Pizza.com - Italia",
      rolename:"Admin Role",
      roletype:"Admin",
    },
    {
      srno: "5",
      name: "Salem RR -ECR",
      resturant:"Pizza.com - Italia",
      rolename:"Admin Role",
      roletype:"Admin",
    },
  ];

  const Ticketcolumns = [
   

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
  
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },

      responsive: ["xs", "md"],
    },

    {
      title: "Resturant",
      dataIndex: "resturant",
      key: "resturant",
  
      sorter: (a, b) => a.resturant.localeCompare(b.resturant),
      ellipsis: {
        showTitle: false,
      },

      responsive: ["xs", "md"],
    },

    
    {
        title: "Role Name",
        dataIndex: "rolename",
        key: "rolename",
    
        sorter: (a, b) => a.rolename.localeCompare(b.rolename),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },

      {
        title: "Role Type",
        dataIndex: "roletype",
        key: "roletype",
    
        sorter: (a, b) => a.roletype.localeCompare(b.roletype),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
  

    

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
  
      sorter: (a, b) => a.action - b.action,
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => (
        <div className=" cursor-pointer flex items-center gap-x-3 justify-start">
         
          <EditIcon />

        </div>
      ),
      responsive: ["xs", "md"],
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
    }),
  };

  return (
    <>
      <Layout>
        <div className="px-10 bg-[#f6f6f6] h-screen">
          <h3 className="text-[#636363] text-2xl p-2">Admins List</h3>
          <div className="bg-white px-4 py-4 rounded-md">
            <div className="mb-4 flex flex-row justify-end">
          

              <div className="border border-[#636363] flex items-center py-1 rounded-md px-2 gap-x-2">
                <CiSearch size={20} />
                <input placeholder="Search..." className=" outline-none " />
              </div>
            </div>
            <Table
              columns={Ticketcolumns}
              data={tableObj}
              scroll={{
                x: 700,
              }}
              pagination={{
                current: page,
                pageSize: pageSize,
                showTotal: showTotal,
                hideOnSinglePage: true,
                pageSizeOptions: [5, 10, 15, 20],
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default StaffList;
