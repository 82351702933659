import React from "react";
import Main from "../pages/main/main";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Open from "../pages/formpage/open";
import Document from "../pages/formpage/document";
import Account from "./../dashboadpages/pages/payouts/Account";
import Overview from "../dashboadpages/pages/payouts/overview";
import Delivery from "../pages/formpage/Delivery";
import Dashboard from "./../dashboadpages/pages/Dashboard";
import ResturantList from "./../dashboadpages/pages/Resturants/ResturantList";
import AddResturant from "./../dashboadpages/pages/Resturants/AddResturant";
import ActiveResturant from "./../dashboadpages/pages/Resturants/ActiveResturant";
import DelayedOrder from "./../dashboadpages/pages/Ordermanagement/DelayedOrder";
import FullFillOrder from "./../dashboadpages/pages/Ordermanagement/Fullfillorder";
import CancelOrder from "./../dashboadpages/pages/Ordermanagement/CancelOrder";
import RefundOrder from "./../dashboadpages/pages/Ordermanagement/RefundOrder";
import NewOrder from "./../dashboadpages/pages/Ordermanagement/NewOrder";
// import Login from "../Auth/Login/Login";
import Form from "../pages/formpage/form";
import DocumentList from "./../dashboadpages/pages/DocumentManagement/DocumentList";
import AddDocument from "./../dashboadpages/pages/DocumentManagement/AddDocument";
import CancellationList from "./../dashboadpages/pages/CancellationReasons/ReasonList";
import AddReason from "./../dashboadpages/pages/CancellationReasons/AddReason";
import PromocodeList from "./../dashboadpages/pages/Promocodes/Promocodelist";
// import AddPromocodes from "./../dashboadpages/pages/Promocodes/AddPromocode";
import ResturantBannerList from "./../dashboadpages/pages/ResturantBanner/ResturantBannerList";
import AddResturantBanner from "./../dashboadpages/pages/ResturantBanner/AddResturantBanner";
import CategoryList from "./../dashboadpages/pages/CategoryList/CategoryList";
import AddCategory from "./../dashboadpages/pages/CategoryList/AddCategory";
import UnitSymbolList from "./../dashboadpages/pages/Units/UnitSymbolList";
import AddUnitSymbol from "./../dashboadpages/pages/Units/AddUnitSymbol";
import UnitList from "./../dashboadpages/pages/Units/UnitList";
import AddUnits from "./../dashboadpages/pages/Units/AddUnits";
import TagList from "./../dashboadpages/pages/Tags/TagList";
import AddTag from "./../dashboadpages/pages/Tags/AddTag";
import CuisinesList from "./../dashboadpages/pages/Cuisines/CuisinesList";
import AddCuisines from "./../dashboadpages/pages/Cuisines/AddCuisines";
import AddonsList from "./../dashboadpages/pages/Adons/AdonsList";
import AddAdon from "./../dashboadpages/pages/Adons/AddAdons";
import FoodQuantityList from "./../dashboadpages/pages/FoodQuantity/FoodQuantityList";
import AddFoodQuantity from "./../dashboadpages/pages/FoodQuantity/AddFoodQuantity";
import AddPromocodes from "./../dashboadpages/pages/Promocodes/AddPromocode";
import UserManagement from "./../dashboadpages/pages/UserManagement/UserManagement";
import DeliveryPartnerList from "./../dashboadpages/pages/DeliveryManagement/DeliveryPartnerList";
import PendingPartnerList from "./../dashboadpages/pages/DeliveryManagement/PendingDriverList";
import CreateRole from "./../dashboadpages/pages/Roles/CreateRole";
import RoleList from "./../dashboadpages/pages/Roles/RoleList";
import CreateStaff from "./../dashboadpages/pages/Roles/CreateStaff";
import StaffList from "./../dashboadpages/pages/Roles/StaffList";
import ResturantReport from "./../dashboadpages/pages/Reports/ResturantReport";
import DeliveryReport from "./../dashboadpages/pages/Reports/DeliveryReport";
import OrderReport from "./../dashboadpages/pages/Reports/OrderReports";
import TopUsers from "./../dashboadpages/pages/Reports/Topusers";
import ProfitLossReport from "./../dashboadpages/pages/Reports/ProfitlossReport";
import Invoice from "../dashboadpages/pages/payouts/invoise";
import Quatation from "../dashboadpages/quot/quatation";
import View from "../dashboadpages/quot/view";
import Calender from "../dashboadpages/pages/calander/calender";
import List from "../dashboadpages/quot/list";
import Menu from "../dashboadpages/pages/menu/MenuList";
import ComboList from "../dashboadpages/pages/menu/combolist";
import ResturantMenu from "../dashboadpages/pages/menu/ResturantMenu";

const AppRoutes = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" Component={Main} />
          <Route path="/form" Component={Form} />
          <Route path="/open" Component={Open} />
          <Route path="/Document" Component={Document} />
          <Route path="/account" Component={Account} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<ResturantList />} />
          <Route path="/addresturant" element={<AddResturant />} />
          <Route path="/activeresturant" element={<ActiveResturant />} />
          <Route path="/delayedorder" element={<DelayedOrder />} />
          <Route path="/fullfillorder" element={<FullFillOrder />} />
          <Route path="/cancelorder" element={<CancelOrder />} />
          <Route path="/refundOrder" element={<RefundOrder />} />
          <Route path="/neworder" element={<NewOrder />} />
          <Route path="/documentlist" element={<DocumentList />} />
          <Route path="/adddocument" element={<AddDocument />} />
          <Route path="/cancellationlist" element={<CancellationList />} />
          <Route path="/addreason" element={<AddReason />} />
          <Route path="/promocodelist" element={<PromocodeList />} />
          <Route path="/addpromocode" element={<AddPromocodes />} />
          <Route
            path="/resturantbannerlist"
            element={<ResturantBannerList />}
          />
          <Route path="/addresturantbanner" element={<AddResturantBanner />} />
          <Route path="/categoryList" element={<CategoryList />} />
          <Route path="/addcategory" element={<AddCategory />} />
          <Route path="/unitsymbollist" element={<UnitSymbolList />} />
          <Route path="/addunitsymbol" element={<AddUnitSymbol />} />
          <Route path="/unitlist" element={<UnitList />} />
          <Route path="/addunit" element={<AddUnits />} />
          <Route path="/taglist" element={<TagList />} />
          <Route path="/addtag" element={<AddTag />} />
          <Route path="/cuisineslist" element={<CuisinesList />} />
          <Route path="/addcuisines" element={<AddCuisines />} />
          <Route path="/adonslist" element={<AddonsList />} />
          <Route path="/addadons" element={<AddAdon />} />
          <Route path="/foodquantitylist" element={<FoodQuantityList />} />
          <Route path="/addfoodquantity" element={<AddFoodQuantity />} />
          <Route path="/usermanagement" element={<UserManagement />} />
          <Route
            path="/deliverypartnerlist"
            element={<DeliveryPartnerList />}
          />
          <Route path="/pendingpartnerlist" element={<PendingPartnerList />} />

          <Route path="/createrole" element={<CreateRole />} />
          <Route path="/rolelist" element={<RoleList />} />
          <Route path="/createstaff" element={<CreateStaff />} />
          <Route path="/stafflist" element={<StaffList />} />

          <Route path="/resturantreport" element={<ResturantReport />} />
          <Route path="/deliveryreport" element={<DeliveryReport />} />
          <Route path="/orderreport" element={<OrderReport />} />
          <Route path="/topusersreport" element={<TopUsers />} />
          <Route path="/profitlossreport" element={<ProfitLossReport />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/quortation" element={<Quatation />} />
          <Route path="/list" element={<List />} />
          <Route path="/view" element={<View />} />
          <Route path="/calender" element={<Calender />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/combolist" element={<ComboList />} />
          <Route path="/resturantmenu" element={<ResturantMenu />} />
        </Routes>
        {/* <Route path="/delivery" Component={Delivery} /> */}
      </Router>
    </div>
  );
};

export default AppRoutes;
