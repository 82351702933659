import React from 'react';
import Chart from 'react-apexcharts';

const LineChart = () => {
  const chartOptions = {
    chart: {
      id: 'line-chart',
      
      toolbar: {
        show: false, // Set to false to hide the toolbar with zoom options
      },
    },
    xaxis: {
        categories: ['1', '2', '3', '4', '5', '6', '7'],
        labels: {
          show: false, // Set to false to hide x-axis labels
        },
       
      },
      yaxis: {
        labels: {
          show: false, // Set to false to hide y-axis labels
        },
        
      },
    markers: {
        size: 4, // Adjust the size of the markers as needed
      },



  };

  const chartSeries = [
    {
      name: 'Value',
      data: [0, 0, 0, 38.5, 0, 0, 0],
   
    },
  ];

  return (
    <div>
      <Chart options={chartOptions} series={chartSeries} type="line" height={150} width={200} />
    </div>
  );
};

export default LineChart;
