import React, { useState } from "react";
import Layout from "../../../dashbord/Layout";
import "../../../styles/ordermngt.css";
import popup from '../../../assets/popup.png'; // Import the image for the modal
import Modal from "../../../modal/Modal";

const NewOrder = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <div className="containr">
        <p className="neworder-head">New Orders</p>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Order ID</th>
                <th scope="col">Order Date</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Package Name</th>
                <th scope="col">Booking Date</th>
                <th scope="col">Guest Count</th>
                <th scope="col">Address</th>
                <th scope="col">Total Amount</th>
                <th scope="col">Reason</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>001</td>
                <td>11/04/2024</td>
                <td>Sandeep Kumar</td>
                <td>Andhra Meals Package</td>
                <td>14/04/2024</td>
                <td>50</td>
                <td>#24, Banaswadi, Benagaluru</td>
                <td>3045</td>
                <td>-</td>
                <td>
                  <button className="btn btn-primary" style={{marginBottom: 5}} onClick={openModal}>Accept</button>
                  <button className="btn btn-danger" onClick={openModal}>Reject</button>
                </td>
              </tr>
              <tr>
                <td>002</td>
                <td>14/04/2024</td>
                <td>Venkadesh</td>
                <td>South Indian Meals Package</td>
                <td>16/04/2024</td>
                <td>30</td>
                <td>#27, Banaswadi, Benagaluru</td>
                <td>1545</td>
                <td>-</td>
                <td>
                  <button className="btn btn-primary" style={{marginBottom: 5}} onClick={openModal}>Accept</button>
                  <button className="btn btn-danger" onClick={openModal}>Reject</button>
                </td>
              </tr>
              <tr>
                <td>0033</td>
                <td>17/04/2024</td>
                <td>Vikas Behl</td>
                <td>Punjabi Meals Package</td>
                <td>20/04/2024</td>
                <td>40</td>
                <td>#124, Banaswadi, Benagaluru</td>
                <td>3200</td>
                <td>-</td>
                <td>
                  <button className="btn btn-primary" style={{marginBottom: 5}} onClick={openModal}>Accept</button>
                  <button className="btn btn-danger" onClick={openModal}>Reject</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} >
        <div className="modal-content">
          <img src={popup} alt="Popup" />
        </div>
      </Modal>
    </Layout>
  );
};

export default NewOrder;
