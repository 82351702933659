import React, { useState } from "react";
import Nav from "./nav";
import icon from "./../../assets/icons/right-arrow1.png";
import "./../../styles/open.css";
import { Link } from "react-router-dom";
import Modal from "./../../modal/Modal";
import Cancel from "../../assets/Svg/CancelIcon";
import { FaChevronRight } from "react-icons/fa";
import CancelIcon from "../../assets/Svg/CancelIcon";
import CloseIcon from "../../assets/Svg/CloseIcon";

const Open = () => {
  const [createModal, setCreateModal] = useState(false);
  const [createModalOne, setCreateModalOne] = useState(false);
  const [modalId, setModalId] = useState(0);
  const [range, setRange] = useState("Any");
  const [val, setVal] = useState("Sort by");
  const [selectedCities, setSelectedCities] = useState([]);
  const [isFilterPageOpen, setIsFilterPageOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleCheckboxChange = (city) => {
    const updatedCities = selectedCities.includes(city)
      ? selectedCities.filter((selectedCity) => selectedCity !== city)
      : [...selectedCities, city];

    setSelectedCities(updatedCities);
  };

  const handleFilterBoxClick = () => {
    // Toggle the state to open/close the filter page
    setIsFilterPageOpen(!isFilterPageOpen);
  };
  return (
    <div>
      <Nav />
      <div className="mx-10 mt-6 ">
      <div className="flex justify-between py-8 px-6" style={{boxShadow:"0px 0px 10px rgba(0, 0, 0, 0.2)"}}>  
      <div className="flex items-center gap-x-4">
        <div className="bg-[#ced4da] px-3 py-1 w-fit text-white">1</div>
        <div className="font-semibold"> Business Details</div>
        <div>
        <FaChevronRight />
        </div>
      </div>

      <div className="flex items-center gap-x-4">
        <div className="bg-[#fc2260] px-3 py-1 w-fit text-white">2</div>
        <div className="font-semibold">Business Type</div>
        <div>
        <FaChevronRight />
        </div>
      </div>

      <div className="flex items-center gap-x-4">
        <div className="bg-[#ced4da] px-3 py-1 w-fit text-white">3</div>
        <div className="font-semibold">Document</div>
        <div>
        <FaChevronRight />
        </div>
      </div>
      </div>


        <div className="four-column-container cursor-pointer flex flex-col items-center">
          <form className="">
            <h4 className="font-[DexaBold] mx-auto px-6 w-96 justify-stretch cursor-pointer text-2xl text-[#5e076a] my-9 "> Select Your Business Type</h4>
            <div className="border border-white py-2 mx-80  max-h-full shadow-md  bg-gray-50">
              <div className="flex ">
                <div onClick={() => {
                      // setModalId();
                      setCreateModalOne(true);
                    }} className="border  mx-5 my-5 justify-center w-40 h-40 bg-white shadow-lg shadow-slate-300" >
                  <img src={require("./../../assets/images/cat.png")}  className="w-28 mx-5"/>
                  <label className="mx-12 font-bold hover:text-[#5e076a]"  >Catering</label>
                 
                </div>
                <div className="border    mx-5  my-5 justify-center w-40  bg-white shadow-lg shadow-slate-300">
                <img src={require("./../../assets/images/people.png")}  className="w-28 mx-6"/>
                  <label className="mx-12 font-bold hover:text-[#5e076a]">Resturant
                  </label>
                </div>
                <div className="border  mx-5 my-5 justify-center w-40 h-40 bg-white   shadow-lg shadow-slate-300">
                <img src={require("./../../assets/images/flow.png")}  className="w-24 mx-8 mt-4"/>
                  <label className="mx-14 font-bold hover:text-[#5e076a] " onClick={() => {
                      // setModalId();
                      setCreateModal(true);
                      // setVal(id === 0 ? 'Sort by':id === 1 ? "Cuisines":id === 2 ? "Rating":id === 3 ? "More filters":"Sort by")
                    }}>Flowers</label>
                 
                  {/* Add other options if needed */}
                </div>
              </div>
              <div className="flex ">
                <div className="border border-[#fcfafb] border-r-4 ml-24 mx-10 my-5 justify-center w-40 bg-white  shadow-lg shadow-slate-300">
                <img src={require("./../../assets/images/lig.png")}  className="w-24 ml-8"/>
                  <label className="mx-16 font-extrabold w-14 text-[#5e076a] hover:text-gray-700">Light</label>
                </div>
                <div className="border  mx-10 my-5 justify-center w-40 h-40 bg-white   shadow-lg shadow-slate-300">
                <img src={require("./../../assets/images/base.png")}  className="w-24 mx-8 mt-2"/>
                  <label className="mx-14  font-bold hover:text-[#5e076a]">Marqee</label>
                </div>
              </div>
            </div>
            <div className="flex justify-between px-3 py-3">
              <Link to="/form">
                <button className="border py-2 rounded-md px-2 w-3/4 mx-4 h-10 my-4 bg-[#fc2260] font-semibold  text-white focus:border-[#fc2260] outline-none focus:outline-none">
                  Previous
                </button>
              </Link>
              <Link to="/Document">
                <button className="border font-semibold py-2 rounded-md px-2 w-3/4 mx-4 h-10 my-4 bg-[#fc2260]   text-white focus:border-[#fc2260] outline-none focus:outline-none">
                  Next
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
      <Modal isOpen={createModal} onClose={() => setCreateModal(false)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // gridTemplateColumns: "repeat(2, 1fr)",
            // gridGap: "4%",
            // overflow: "hidden",
            width: 400,
            height: 400,
            overflowY: "auto",
            marginTop: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingInline: 10,
              margin: 0,

              borderBottom: "1px solid grey",
            }}
          >
            <h2>Please Select Minimum 1 Sub Catagory</h2>
            <div onClick={() => setCreateModal(false)}>{/* <Cross /> */}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: "1px solid grey",
            }}
          >
            <div className="flex mx-8">
              <input type="checkbox" />
              <label className="my-4">Decorators</label>
            </div>
            <div className="flex mx-8">
              <input type="checkbox" />
              <label className="my-4">Bouquet</label>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: 20,
              paddingInline: 20,
              alignItems: "center",
              gap: 30,
            }}
          >
            {/* <p style={{ color: "#1C1C1C" }}>Clear All</p> */}
          </div>
        </div>
      </Modal>
      <Modal isOpen={createModalOne} onClose={() => setCreateModalOne(false)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // gridTemplateColumns: "repeat(2, 1fr)",
            // gridGap: "4%",
            // overflow: "hidden",
            width: 400,
            height: 400,
            overflowY: "auto",
            marginTop: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              paddingLeft: 10,
              // height:20,
              borderBottom: "1px solid #e90092",
              //   backgroundColor: "#ebf7ff"
              color: "#ff0097",
              fontSize: 18,
            }}
          >
            Please Select Minimum 1 Sub Category
            <div
              onClick={() => setCreateModalOne(false)}
              className="ml-10 my-2"
            >
              <CloseIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: "1px solid grey",
            }}
          >
            <div>
              <div className="flex mx-8">
                <input type="checkbox" />
                <label className="my-4">Marriage</label>
              </div>
              <div className="flex mx-8">
                <input type="checkbox" />
                <label className="my-4">Friends Party</label>
              </div>
              <div className="flex mx-8">
                <input type="checkbox" />
                <label className="my-4">Family Meet</label>
              </div>
              <div className="flex mx-8">
                <input type="checkbox" />
                <label className="my-4">Corporate Caterers</label>
              </div>
              <div className="flex mx-8">
                <input type="checkbox" />
                <label className="my-4">House Party</label>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: 20,
              paddingInline: 20,
              alignItems: "center",
              gap: 30,
            }}
          >
            <button
              style={{
                backgroundColor: "#ef4f5f",
                outline: "none",
                border: "none",
                color: "white",
                paddingInline: 40,
                borderRadius: 10,
                paddingBlock: 15,
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Open;
