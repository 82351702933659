import React from "react";
import Layout from "../../../dashbord/Layout";
import { useNavigate } from "react-router-dom";
import "../../../styles/ordermngt.css";

const RefundOrder = () => {
  
  return (
    <>
       <Layout>
      <div className="containr">
        <p className="neworder-head">Refund Orders</p>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Order ID</th>
                <th scope="col">Order Date</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Package Name</th>
                <th scope="col">Booking Date</th>
                <th scope="col">Guest Count</th>
                <th scope="col">Address</th>
                <th scope="col">Total Amount</th>
                <th scope="col">Reason</th>
                <th scope="col">Action</th>
                <th scope="col">User Type</th>
                <th scope="col">Delivery Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>001</td>
                <td>11/04/2024</td>
                <td>Sandeep Kumar</td>
                <td>Andhra Meals Package</td>
                <td>14/04/2024</td>
                <td>50</td>
                <td>#24, Banaswadi, Benagaluru</td>
                <td>3045</td>
                <td>-</td>
                <td>Pending</td>
                <td>New Customer</td>
                <td>Pending</td>
              </tr>
              <tr>
                <td>002</td>
                <td>14/04/2024</td>
                <td>Venkadesh</td>
                <td>South Indian Meals Package</td>
                <td>16/04/2024</td>
                <td>30</td>
                <td>#27, Banaswadi, Benagaluru</td>
                <td>1545</td>
                <td>-</td>
                <td>Pending</td>
                <td>New Customer</td>
                <td>Pending</td>
              </tr>
              <tr>
                <td>0033</td>
                <td>17/04/2024</td>
                <td>Vikas Behl</td>
                <td>Punjabi Meals Package</td>
                <td>20/04/2024</td>
                <td>40</td>
                <td>#124, Banaswadi, Benagaluru</td>
                <td>3200</td>
                <td>-</td>
                <td>Pending</td>
                <td>New Customer</td>
                <td>Pending</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
    </>
  );
};

export default RefundOrder;
