import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../../dashbord/Layout";

const Account = () => {
  return (
    <Layout>
      <div className="flex justify-center items-center h-full">
        <div className="px-8 py-12 w-full max-w-xl bg-white rounded-lg shadow-lg">
          <h2 className="text-3xl font-semibold mb-4">Enter Bank Details</h2>
          <p className="text-gray-700 mb-8 flex justify-center">(As Per Bank Records)</p>

          <form className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-4">
              <div>
                <label htmlFor="accountName" className="block text-gray-700 font-semibold">Account Name</label>
                <input
                  id="accountName"
                  name="accountName"
                  type="text"
                  placeholder="Enter Account Name"
                  className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-[#fc2260]"
                />
              </div>
              <div>
                <label htmlFor="ifsccode" className="block text-gray-700 font-semibold">IFSC Code</label>
                <input
                  id="ifsccode"
                  name="ifsccode"
                  type="text"
                  placeholder="Enter IFSC Code"
                  className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-[#fc2260]"
                />
              </div>
              <div>
                <label htmlFor="accountNumber" className="block text-gray-700 font-semibold">Account Number</label>
                <input
                  id="accountNumber"
                  name="accountNumber"
                  type="text"
                  placeholder="Enter Account Number"
                  className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-[#fc2260]"
                />
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <label htmlFor="bankName" className="block text-gray-700 font-semibold">Bank Name</label>
                <input
                  id="bankName"
                  name="bankName"
                  type="text"
                  placeholder="Enter Bank Name"
                  className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-[#fc2260]"
                />
              </div>
              <div>
                <label htmlFor="branchName" className="block text-gray-700 font-semibold">Branch Name</label>
                <input
                  id="branchName"
                  name="branchName"
                  type="text"
                  placeholder="Enter Branch Name"
                  className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-[#fc2260]"
                />
              </div>
              <div>
                <label htmlFor="branchAddress" className="block text-gray-700 font-semibold">Branch Address</label>
                <input
                  id="branchAddress"
                  name="branchAddress"
                  type="text"
                  placeholder="Enter Branch Address"
                  className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-[#fc2260]"
                />
              </div>
            </div>
          </form>

          <div className="mt-8 flex justify-center">
            <Link to="/">
              <button className="border border-[#0e0593] bg-[#0e0593] text-white rounded-md py-2 px-10 font-semibold hover:bg-opacity-90 focus:outline-none focus:bg-opacity-90">
                Submit
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Account;
