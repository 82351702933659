import React, { useState } from "react";
import Layout from "../../../dashbord/Layout";
import { useNavigate } from "react-router-dom";
import EyeIcon from "./../../../assets/Svg/EyeIcon";
import Table from "../../../dashbord/Table";
import { Dropdown, Menu } from "antd";
import { CiMenuKebab } from "react-icons/ci";
import EditIcon from "./../../../assets/Svg/EditIcon";
import { FaPlus } from "react-icons/fa6";
import DeleteIcon from "./../../../assets/Svg/DeleteIcon";
import { CiSearch } from "react-icons/ci";
import List from "./../../../assets/Svg/List";

const DeliveryReport = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [viewData, setViewData] = useState(null);

  const showTotal = (pages, range) => {
    const typeData = [
      {
        id: "1",
        label: "5",
      },
      {
        id: "2",
        label: "15",
      },
      {
        id: "3",
        label: "25",
      },
      {
        id: "4",
        label: "35",
      },
    ];
  };

  const tableObj = [
    {
      srno: "1",
      resturant: "Pizza.com - Italia",
      email: "********",
      phoneno:"*******",
      ratings:"*******",
      Address:"Damrak 87, 1012 LP Amsterdam, Netherlands",
      city:"Bangalore",
      Area:"Kormangala",
      totalorder:"₹0.00",
      ttlresturanterng:"₹1057.22",
      pendingpyout:"₹000",
      payoutcmpltd:"₹0.00"

    },
    {
      srno: "2",
      resturant: "Pizza.com - Italia",
      email: "********",
      phoneno:"*******",
      ratings:"*******",
      Address:"Damrak 87, 1012 LP Amsterdam, Netherlands",
      city:"Bangalore",
      Area:"Kormangala",
      totalorder:"₹0.00",
      ttlresturanterng:"₹1057.22",
      pendingpyout:"₹000",
      payoutcmpltd:"₹0.00"
    },
    {
      srno: "3",
       resturant: "Pizza.com - Italia",
      email: "********",
      phoneno:"*******",
      ratings:"*******",
      Address:"Damrak 87, 1012 LP Amsterdam, Netherlands",
      city:"Bangalore",
      Area:"Kormangala",
      totalorder:"₹0.00",
      ttlresturanterng:"₹1057.22",
      pendingpyout:"₹000",
      payoutcmpltd:"₹0.00"
    },
    {
      srno: "4",
      resturant: "Pizza.com - Italia",
      email: "********",
      phoneno:"*******",
      ratings:"*******",
      Address:"Damrak 87, 1012 LP Amsterdam, Netherlands",
      city:"Bangalore",
      Area:"Kormangala",
      totalorder:"₹0.00",
      ttlresturanterng:"₹1057.22",
      pendingpyout:"₹000",
      payoutcmpltd:"₹0.00"
    },
    {
      srno: "5",
       resturant: "Pizza.com - Italia",
      email: "********",
      phoneno:"*******",
      ratings:"*******",
      Address:"Damrak 87, 1012 LP Amsterdam, Netherlands",
      city:"Bangalore",
      Area:"Kormangala",
      totalorder:"₹0.00",
      ttlresturanterng:"₹1057.22",
      pendingpyout:"₹000",
      payoutcmpltd:"₹0.00"
    },
  ];

  const Ticketcolumns = [
    {
      title: "Driver",
      dataIndex: "resturant",
      key: "resturant",

      sorter: (a, b) => a.resturant.localeCompare(b.resturant),
      ellipsis: {
        showTitle: false,
      },

      responsive: ["xs", "md"],
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",

      sorter: (a, b) => a.email.localeCompare(b.email),
      ellipsis: {
        showTitle: false,
      },

      responsive: ["xs", "md"],
    },

    {
        title: "Phone Number",
        dataIndex: "phoneno",
        key: "phoneno",
  
        sorter: (a, b) => a.phoneno.localeCompare(b.phoneno),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Ratings",
        dataIndex: "ratings",
        key: "ratings",
  
        sorter: (a, b) => a.ratings.localeCompare(b.ratings),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Address",
        dataIndex: "Address",
        key: "Address",
  
        sorter: (a, b) => a.Address.localeCompare(b.Address),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "City",
        dataIndex: "city",
        key: "city",
  
        sorter: (a, b) => a.city.localeCompare(b.city),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Area",
        dataIndex: "Area",
        key: "Area",
  
        sorter: (a, b) => a.Area.localeCompare(b.Area),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Total Order",
        dataIndex: "totalorder",
        key: "totalorder",
  
        sorter: (a, b) => a.totalorder.localeCompare(b.totalorder),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Total Resturant Earning",
        dataIndex: "ttlresturanterng",
        key: "ttlresturanterng",
  
        sorter: (a, b) => a.ttlresturanterng.localeCompare(b.ttlresturanterng),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Pending Payout",
        dataIndex: "pendingpyout",
        key: "pendingpyout",
  
        sorter: (a, b) => a.pendingpyout.localeCompare(b.pendingpyout),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Payout Completed",
        dataIndex: "payoutcmpltd",
        key: "payoutcmpltd",
  
        sorter: (a, b) => a.payoutcmpltd.localeCompare(b.payoutcmpltd),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },

    
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
    }),
  };

  return (
    <>
      <Layout>
        <div className="px-10 bg-[#f6f6f6] h-screen">
          <h3 className="text-[#636363] text-2xl p-2">Delivery People Report</h3>
          <div className="bg-white px-4 py-4 rounded-md">
            <div className="mb-4 flex flex-row justify-between">
                <div className="flex gap-x-5">
                <button
                onClick={() => navigate("/addpromocode")}
                className="flex items-center bg-[#5e076a] text-white px-4 py-1 rounded-md"
              >
                Export
              </button>

              <button
                onClick={() => navigate("/addpromocode")}
                className="flex items-center bg-[#5e076a] text-white px-4 py-1 rounded-md"
              >
              Date Filter
              </button>
                </div>
   

              <div className="border border-[#636363] flex items-center py-1 rounded-md px-2 gap-x-2">
                <CiSearch size={20} />
                <input placeholder="Search..." className=" outline-none " />
              </div>
            </div>

            <Table
              columns={Ticketcolumns}
              data={tableObj}
              scroll={{
                x: 700,
              }}
              pagination={{
                current: page,
                pageSize: pageSize,
                showTotal: showTotal,
                hideOnSinglePage: true,
                pageSizeOptions: [5, 10, 15, 20],
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DeliveryReport;
