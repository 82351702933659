import React, { useState } from "react";
import Layout from "../../dashbord/Layout";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import Table from "../../dashbord/Table";

const List = () => {
  const navigate = useNavigate();
  const [viewData, setViewData] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const View = () => {
    navigate("/view");
  };
  const showTotal = (pages, range) => {
    const typeData = [
      {
        id: "1",
        label: "5",
      },
      {
        id: "2",
        label: "15",
      },
      {
        id: "3",
        label: "25",
      },
      {
        id: "4",
        label: "35",
      },
    ];
  };
  const tableObj = [
    {
      clientName: "qwe34rty",
      qutationDate: "11/11/2023",
      bookingDate: "Pragyan",
    },
    {
      clientName: "qwe34rty",
      qutationDate: "11/11/2023",
      bookingDate: "Pragyan",
    },
    {
      clientName: "qwe34rty",
      qutationDate: "11/11/2023",
      bookingDate: "Pragyan",
    },
    {
      clientName: "qwe34rty",
      qutationDate: "11/11/2023",
      bookingDate: "Pragyan",
    },
  ];
  const menu = (
    <Button
      items={[
        {
          label: (
            <div
              className="flex items-center pl-[20px]  text-[12px] uppercase"
              onClick={View}
            >
              view
            </div>
          ),
          key: "0",
        },
      ]}
    />
  );
  const Ticketcolumns = [
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      sorter: (a, b) => a.clientName > b.clientName,
      ellipsis: {
        showTitle: false,
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            setViewData(record);
            if (viewData) {
              // showViewPage();
            }
          },
        };
      },

      responsive: ["xs", "md"],
    },
    {
      title: "Quation Date",
      dataIndex: "quationDate",
      key: "quationDate",
      sorter: (a, b) => a.quationDate > b.quationDate,
      ellipsis: {
        showTitle: false,
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            setViewData(record);
            if (viewData) {
              // showViewPage();
            }
          },
        };
      },

      responsive: ["xs", "md"],
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      sorter: (a, b) => a.bookingDate.localeCompare(b.bookingDate),
      ellipsis: {
        showTitle: false,
      },

      responsive: ["xs", "md"],
    },

    {
      title: "View Quation",
      dataIndex: "view",
      key: "view",
      align: "center",
      sorter: (a, b) => a.action - b.action,
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => (
        <Button
          overlay={menu}
          trigger={"click"}
          placement={"bottomRight"}
          onClick={View}
        >
          View
        </Button>
      ),
      responsive: ["xs", "md"],
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
    }),
  };
  return (
    <>
      <Layout>
        <div className="p-10">
          <h1 className="text-xl font-medium">LISTS</h1>
          <Table
            columns={Ticketcolumns}
            data={tableObj}
            scroll={{
              x: 700,
            }}
            pagination={{
              current: page,
              pageSize: pageSize,
              showTotal: showTotal,
              hideOnSinglePage: true,
              pageSizeOptions: [5, 10, 15, 20],
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      </Layout>
    </>
  );
};
export default List;
