import React, { useState } from "react";
import Layout from "../../../dashbord/Layout";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import Table from "../../../dashbord/Table";

const ComboList =() => {
    const navigate = useNavigate();
    const [viewData, setViewData] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const Edit = () => {
        navigate("/menu");
      };
    const showTotal = (pages, range) => {
        const typeData = [
          {
            id: "1",
            label: "5",
          },
          {
            id: "2",
            label: "15",
          },
          {
            id: "3",
            label: "25",
          },
          {
            id: "4",
            label: "35",
          },
        ];
      };
      const tableObj = [
        {
          name: "qwe34rty",
          cusion: "",
          price:"" ,
          category:"",
          unit:"",
        },
        {
            name: "qwe34rty",
            cusion: "",
            price:"" ,
            category:"",
            unit:"",
        },
        {
            name: "qwe34rty",
            cusion: "",
            price:"" ,
            category:"",
            unit:"",
        },
        {
            name: "qwe34rty",
            cusion: "",
            price:"" ,
            category:"",
            unit:"",
        },
      ];
      const menu = (
        <Button
          items={[
            {
              label: (
                <div
                  className="flex items-center pl-[20px]  text-[12px] uppercase"
                  onClick={Edit}
                >
                 Edit
                </div>
              ),
              key: "0",
            },
          ]}
        />
      );
      const Ticketcolumns = [
        {
          title: " Name",
          dataIndex: "name",
          key: "name",
          sorter: (a, b) => a.name > b.name,
          ellipsis: {
            showTitle: false,
          },
          onCell: (record, rowIndex) => {
            return {
              onClick: () => {
                setViewData(record);
                if (viewData) {
                  // showViewPage();
                }
              },
            };
          },
    
          responsive: ["xs", "md"],
        },
        {
          title: "Cuisines",
          dataIndex: "cusion",
          key: "cusion",
          sorter: (a, b) => a.cusion > b.cusion,
          ellipsis: {
            showTitle: false,
          },
          onCell: (record, rowIndex) => {
            return {
              onClick: () => {
                setViewData(record);
                if (viewData) {
                  // showViewPage();
                }
              },
            };
          },
    
          responsive: ["xs", "md"],
        },
        {
          title: "Unit",
          dataIndex: "unit",
          key: "unit",
          sorter: (a, b) => a.unit.localeCompare(b.unit),
          ellipsis: {
            showTitle: false,
          },
    
          responsive: ["xs", "md"],
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            sorter: (a, b) => a.price.localeCompare(b.price),
            ellipsis: {
              showTitle: false,
            },
      
            responsive: ["xs", "md"],
          },
      
        {
          title: "Edit",
          dataIndex: "edit",
          key: "edit",
          align: "center",
          sorter: (a, b) => a.action - b.action,
          ellipsis: {
            showTitle: false,
          },
          render: (_, record) => (
            <Button
              overlay={menu}
              trigger={"click"}
              placement={"bottomRight"}
              onClick={Edit}
            >
             Edit
            </Button>
          ),
          responsive: ["xs", "md"],
        },
      ];
      const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
        },
        getCheckboxProps: (record) => ({
          disabled: record.name === "Disabled User", // Column configuration not to be checked
        }),
      };
    return (
        <>
        <Layout>
        <div className="p-10">
          <h1 className="text-xl font-medium">LISTS</h1>
          <Table
            columns={Ticketcolumns}
            data={tableObj}
            scroll={{
              x: 700,
            }}
            pagination={{
              current: page,
              pageSize: pageSize,
              showTotal: showTotal,
              hideOnSinglePage: true,
              pageSizeOptions: [5, 10, 15, 20],
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
        </Layout>
        </>
    )
}

export default ComboList;