import React from 'react';
import Chart from 'react-apexcharts';

const RadialBarChart = () => {
  const chartOptions = {
    chart: {
      id: 'radial-bar-chart',
      type: 'radialBar',
      height: '350',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '60%', // Adjust the size of the inner circle
        },
        dataLabels: {
          showOn: 'always',
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '13px',
          },
          value: {
            color: '#111',
            fontSize: '30px',
            show: true,
          },
        },
        track: {
          background: '#f2f2f2',
          strokeWidth: '100%',
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.15,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    series: [70, 40, 20], // Values for the radial bars
    labels: ['Delivered', 'Cancelled', 'Failed'], // Labels for each radial bar
  };

  return (
    <div>
      <Chart options={chartOptions} series={chartOptions.series} type="radialBar"  height={250} width={200}/>
    </div>
  );
};

export default RadialBarChart;
