import React from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";

const Layout = ({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-2">
          <Sidebar />
      </div>
      <div className="col-span-10">
        <Header />
        <div className="h-[630px] overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
