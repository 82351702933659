import React from "react";
import Layout from "../../../dashbord/Layout";

const Overview = () => {
  return (
    <>
      <Layout>
        <div className="px-8 py-12">
          <div className="mb-8">
            <h1 className="font-bold text-3xl mb-2">Billing</h1>
            <p className="text-gray-700">
              Track and find all the details about our Billing, your stats and
              revenues.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="bg-indigo-900 text-white rounded-xl p-6 flex flex-col items-center justify-center">
              <p className="text-lg font-medium mb-2">Total Earnings</p>
              <p className="text-2xl">&#8377; 0</p>
            </div>
            <div className="bg-indigo-900 text-white rounded-xl p-6 flex flex-col items-center justify-center">
              <p className="text-lg font-medium mb-2">Total Paid</p>
              <p className="text-2xl">&#8377; 0</p>
            </div>
            <div className="bg-indigo-900 text-white rounded-xl p-6 flex flex-col items-center justify-center">
              <p className="text-lg font-medium mb-2">Total to be Transferred</p>
              <p className="text-2xl">&#8377; 0</p>
            </div>
            <div className="bg-indigo-900 text-white rounded-xl p-6 flex flex-col items-center justify-center">
              <p className="text-lg font-medium mb-2">See all your Invoices</p>
              
            </div>
          </div>

          <div className="border-t mt-8 pt-8">
            <h2 className="text-2xl font-semibold mb-4">Billing Cycle Record</h2>
            <div className="border bg-gray-100 border-gray-300 shadow-md rounded-lg overflow-hidden">
              <div className="grid grid-cols-1 sm:grid-cols-5 gap-0">
                <div className="border-b py-3 px-4 text-sm font-semibold text-gray-700">Order ID</div>
                <div className="border-b py-3 px-4 text-sm font-semibold text-gray-700">Book Date</div>
                <div className="border-b py-3 px-4 text-sm font-semibold text-gray-700">Order Amount</div>
                <div className="border-b py-3 px-4 text-sm font-semibold text-gray-700">PAID BY</div>
                <div className="border-b py-3 px-4 text-sm font-semibold text-gray-700">Pay</div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Overview;
