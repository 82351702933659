import React from 'react'

const CloseIcon = () => {
  return (
    <div> <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="#6c757d"
    className="h-6 w-6 "
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg></div>
  )
}

export default CloseIcon