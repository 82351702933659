import React from 'react';
import Chart from 'react-apexcharts';

const ColumnChart = () => {
  const chartOptions = {
    chart: {
      id: 'column-chart',
      type: 'bar', // You can use 'column' instead of 'bar' for a column chart
      toolbar: {
        show: false, // Set to false to hide the toolbar with zoom options
      },
    },
    xaxis: {
      categories: ['January', 'February', 'March', 'April', 'May',"June","July","August","September","October","November","December"],
    },
    plotOptions: {
        bar: {
          columnWidth: '30%', // Adjust the width of the bars as needed
        },
      },
  };

  const chartSeries = [
    {
      name: 'Values',
      data: [30, 25, 15, 30, 45,55,60,70,25,80,65,90],
    },
  ];

  return (
    <div>
      <Chart options={chartOptions} series={chartSeries} type="bar"  />
    </div>
  );
};

export default ColumnChart;
