import React from "react";
import Layout from "../../../dashbord/Layout";

const AddResturant = () => {
  return (
    <>
      <Layout>
        <div className="px-10 py-4">
            <h3 className="font-semibold text-[22px]">Add New Restaurant</h3>
      
          <div className="grid gap-y-10">
          <div className="grid grid-cols-3 items-center">
            <div className="col-span-1">Owner Name:</div>
            <div className="col-span-2 w-full">
              <input type="text" placeholder="Write Owner Name Here" className="w-full border outline-none py-3 px-4" />
            </div>
          </div>

          <div className="grid grid-cols-3 items-center">
            <div className="col-span-1">Mobile No:</div>
            <div className="col-span-2 w-full">
              <input type="text" placeholder="Write Mobile Number Here" className="w-full border outline-none py-3 px-4" />
            </div>
            
          </div>
        
          <div className="grid grid-cols-3 items-center">
            <div className="col-span-1">Email ID:</div>
            <div className="col-span-2 w-full">
              <input placeholder="Write Email Id Here" className="w-full border outline-none py-3 px-4" />
            </div>
          </div>

          <div className="grid grid-cols-3 items-center">
            <div className="col-span-1">Entity Name:</div>
            <div className="col-span-2 w-full">
              <input type="text" placeholder="Write Entity Name Here" className="w-full border outline-none py-3 px-4" />
            </div>
          </div>

          <div className="grid grid-cols-3 items-center">
            <div className="col-span-1">Address:</div>
            <div className="col-span-2 w-full">
              <input type="text" placeholder="Write Address Here" className="w-full border outline-none py-3 px-4" />
            </div>
          </div>

          <div className="grid grid-cols-3 items-center">
            <div className="col-span-1">PAN Number:</div>
            <div className="col-span-1 w-full">
              <input type="text" placeholder="Please Enter Valid PAN Number" className="w-full border outline-none py-3 px-4" />
            </div>
            <div className="col-span-1 w-full">
              <input type="file" className="w-full  outline-none py-3 px-4" />
            </div>
          </div>

          <div className="grid grid-cols-3 items-center">
            <div className="col-span-1">GST Number:</div>
            <div className="col-span-1 w-full">
              <input type="text" placeholder="Please Enter Valid GST Number" className="w-full border outline-none py-3 px-4" />
            </div>
            <div className="col-span-1 w-full">
              <input type="file" className="w-full  outline-none py-3 px-4" />
            </div>
          </div>

          <div className="grid grid-cols-3 items-center">
            <div className="col-span-1">FSSAI Number:</div>
            <div className="col-span-1 w-full">
              <input type="text" placeholder="Please Enter Valid FSSAI Number" className="w-full border outline-none py-3 px-4" />
            </div>
            <div className="col-span-1 w-full">
              <input type="file" className="w-full  outline-none py-3 px-4" />
            </div>
          </div>

          
          <div className="grid grid-cols-3 items-center">
            <div className="col-span-1">Owner Photograph:</div>
           
            <div className="col-span-1 w-full">
              <input type="file" className="w-full  outline-none py-3 px-4" />
            </div>
            <div className="col-span-1 w-full">
              <img src="" alt="Owner Photo"/>
            </div>
          </div>

          <div className="flex justify-between">
            <button className="bg-white border border-[#5e076a] py-3 px-6 text-[#e90092] rounded-lg">Cancel</button>
            <button className="bg-[#5e076a] py-3 px-6 text-white rounded-lg">Submit</button>
          </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AddResturant;
