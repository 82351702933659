import { Button } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AccountIcon = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const LogOut = () => {
    navigate("/");
  };
  const handleIconClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <div className="relative">
      <button onClick={handleIconClick}>
        {/* Your Account Icon */}
        <svg
          viewBox="0 0 16 16"
          width="25px"
          height="25px"
          focusable="false"
          role="img"
          aria-label="person fill"
          aria-hidden="true"
          alt="avatar"
          xmlns="http://www.w3.org/2000/svg"
          fill="#00ffe4"
          className="bi-person-fill b-icon bi"
        >
          <g>
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
          </g>
        </svg>
      </button>

      {isDropdownOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-slate-50 border  shadow-lg">
          {/* Dropdown content */}
          <ul>
            <li className="ml-14 font-medium  mt-6 cursor-pointer">
              ID: 56AK71
            </li>
            <Button
              className="ml-12 mb-6 bg-slate-500 text-white text-xl  shadow-sm h-10 font-medium  mt-10 cursor-pointer"
              onClick={LogOut}
            >
              Log Out
            </Button>

            {/* Add more menu items as needed */}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AccountIcon;
