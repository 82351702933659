import React from "react";
import Nav from "./nav";
import FormPage from "./FormPage";


const Form = () => {
    return (
        <div >
            <Nav />
            <FormPage  />
        </div>
    );
};

export default Form;