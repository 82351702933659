import React, { useState } from "react";
import Layout from "../../../dashbord/Layout";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { RxCross1 } from "react-icons/rx";
import Modal from "../../../modal/Modal";
import popup from "../../../assets/popup.png"

const AddPromocodes = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState();
  const [timeslot, setTimeslot] = useState(false);
  const [all, setAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <Layout>
        <div className="px-10 bg-[#f6f6f6] h-fit">
          <h3 className="text-[#636363] text-2xl p-2">Add Banner</h3>
          <div className="bg-white px-4 py-4 rounded-md">
            <div className="grid grid-cols-2 gap-x-8 gap-y-4">
              {/* Title */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">
                  Title<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Title"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              {/* Restaurant */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Resturant</label>
                <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
                  <option selected disabled>
                    Select Restaurant
                  </option>
                  <option>Restaurant one</option>
                  <option>Restaurant two</option>
                  <option>Restaurant three</option>
                  <option>Restaurant four</option>
                  <option>Restaurant five</option>
                </select>
              </div>

              {/* Description */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">
                  Description<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Description"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              {/* Promocode */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">
                  Promocode<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Promocode"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              {/* Offer Type */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Offer Type</label>
                <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
                  <option selected disabled>
                    Offer Type
                  </option>
                  <option>Amount</option>
                  <option>Percent</option>
                </select>
              </div>

              {/* Maximum Amount */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">
                  Maximum Amount<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Maximum Amount"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              {/* Minimum Order Value */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">
                  Minimum Order Value<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Minimum Order Value"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              {/* Discount(%) */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">
                  Discount(%)<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="0-100"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              {/* Admin Contribution */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">
                  Admin Contribution
                </label>
                <input
                  type="text"
                  placeholder="Admin Contribution"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              {/* Usage Limit Per Coupon */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">
                  Usage Limit Per Coupon*
                </label>
                <input
                  type="text"
                  placeholder="Enter Per coupon"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              {/* Usage Limit Per User */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">
                  Usage Limit Per User*
                </label>
                <input
                  type="text"
                  placeholder="Enter Per User"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              {/* Available From */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Available From*</label>
                <input
                  type="date"
                  placeholder="No date selected"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              {/* Expiry Date */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Expiry Date*</label>
                <input
                  type="date"
                  placeholder="No date selected"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              {/* Status */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Status</label>
                <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
                  <option selected disabled>
                    Select Status
                  </option>
                  <option>Active</option>
                  <option>Inactive</option>
                </select>
              </div>

              {/* Promocode Type */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Promocode Type</label>
                <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
                  <option selected disabled>
                    Select Promocode Type
                  </option>
                  <option>Private</option>
                  <option>Public</option>
                </select>
              </div>

              {/* Payment Methods */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Payment Methods</label>
                <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
                  <option selected disabled>
                    Select Payment Methods
                  </option>
                  <option>COD</option>
                  <option>Online</option>
                  <option>Both</option>
                </select>
              </div>

              {/* Image */}
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1" htmlFor="qq">
                  Image
                </label>
                <label htmlFor="fileInput">
                  <div className="bg-[#0e0593] text-white w-fit py-1 px-2 rounded-md" onClick={openModal}>
                    Choose a file
                  </div>
                </label>
                <input type="file" id="fileInput" hidden />
              </div>
            </div>

            <div className="mt-10">
              <h3 className="text-[#6e6b7b] font-medium py-1">
                Coupon Availability
              </h3>

              {timeslot && (
                <div className="grid grid-cols-3 items-center gap-x-6">
                  <div className="flex flex-col col-span-1">
                    <label className="text-[#6e6b7b] py-1">From*</label>
                    <input
                      type="time"
                      placeholder="No date selected"
                      className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                    />
                  </div>

                  <div className="flex flex-col col-span-1">
                    <label className="text-[#6e6b7b] py-1">To*</label>
                    <input
                      type="time"
                      placeholder="No date selected"
                      className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                    />
                  </div>

                  <div className=" col-span-1  relative top-6 ">
                    <button
                      onClick={openModal}
                      className="flex items-center border gap-x-4 border-[#5e076a]  text-[#5e076a] px-6 py-2 rounded-md text-sm"
                    >
                      <RxCross1 size={14} className="relative top-0.5" />{" "}
                      <span>Delete</span>
                    </button>
                  </div>
                </div>
              )}

              <div className="mt-7 mb-4">
                <button
                  onClick={openModal}
                  className="flex items-center gap-2 bg-[#0e0593] text-white text-sm px-2 py-2 rounded-md"
                >
                  <FaPlus color="white" size={15} /> Add New Time Slot
                </button>
              </div>

              <div className="grid grid-cols-7 gap-4">
                <div className="flex items-center">
                  <input
                    checked={all}
                    onChange={() => setAll(!all)}
                    type="checkbox"
                  />
                  <label className="text-[#6e6b7b] pl-2">All</label>
                </div>

                {all === false && (
                  <>
                    <div className="flex items-center">
                      <input type="checkbox" />
                      <label className="text-[#6e6b7b] pl-2">Monday</label>
                    </div>
                    <div className="flex items-center">
                      <input type="checkbox" />
                      <label className="text-[#6e6b7b] pl-2">Tuesday</label>
                    </div>
                    <div className="flex items-center">
                      <input type="checkbox" />
                      <label className="text-[#6e6b7b] pl-2">Wednesday</label>
                    </div>
                    <div className="flex items-center">
                      <input type="checkbox" />
                      <label className="text-[#6e6b7b] pl-2">Thursday</label>
                    </div>
                    <div className="flex items-center">
                      <input type="checkbox" />
                      <label className="text-[#6e6b7b] pl-2">Friday</label>
                    </div>
                    <div className="flex items-center">
                      <input type="checkbox" />
                      <label className="text-[#6e6b7b] pl-2">Saturday</label>
                    </div>
                    <div className="flex items-center">
                      <input type="checkbox" />
                      <label className="text-[#6e6b7b] pl-2">Sunday</label>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="py-4">
              <button className="flex items-center bg-[#0e0593] text-white px-6 py-2 rounded-md text-sm" onClick={openModal}>
                Save
              </button>
            </div>
          </div>
        </div>
        {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} >
        <div className="modal-content">
          <img src={popup} alt="Popup" />
        </div>
      </Modal>
      </Layout>
    </>
  );
};

export default AddPromocodes;
