import React from "react";
import Layout from "../../../dashbord/Layout";

const Invoice = () => {
  return (
    <Layout>
      <div className="px-8 py-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-3xl font-semibold mb-6 text-center">
          Invoice Details
        </h2>
        <div className="overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr className="text-left bg-gray-200">
                <th className="px-4 py-3 font-semibold">Invoice ID</th>
                <th className="px-4 py-3 font-semibold">Payout ID</th>
                <th className="px-4 py-3 font-semibold">Total Amount</th>
                <th className="px-4 py-3 font-semibold">Service Fee @ 10%</th>
                <th className="px-4 py-3 font-semibold">
                  GST @ 18% on Service Fee
                </th>
                <th className="px-4 py-3 font-semibold">
                  TDS @ 1% of Total Value
                </th>
                <th className="px-4 py-3 font-semibold">Total Net Amount</th>
                <th className="px-4 py-3 font-semibold">Invoice Date</th>
              </tr>
            </thead>
            <tbody>
              {/* Sample data rows */}
              <tr className="hover:bg-gray-100">
                <td className="border px-4 py-3">09873</td>
                <td className="border px-4 py-3">98735642</td>
                <td className="border px-4 py-3">&#8377; 1000</td>
                <td className="border px-4 py-3">&#8377; 100</td>
                <td className="border px-4 py-3">&#8377; 180</td>
                <td className="border px-4 py-3">&#8377; 10</td>
                <td className="border px-4 py-3">&#8377; 760</td>
                <td className="border px-4 py-3">17/04/2024</td>
              </tr>
              {/* Additional data rows */}
            </tbody>
          </table>
        </div>
        <div className="text-center mt-8">
          <button className="px-6 py-3 bg-[#0e0593] text-white font-semibold rounded-md shadow-md hover:bg-[#baf200]  focus:outline-none focus:ring-2 focus:ring-blue-500">
            Generate Invoice
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Invoice;
