import React from "react";
import './../../styles/nav.css';
import menu from './../../assets/icons/menu.png'
const Nav = () => {
    return (
    <div className="px-5 flex items-center shadow-md ">
        <p className="text-[#fc2260] font-bold text-3xl ">GEZTE</p>
    </div>
    );
};

export default Nav;