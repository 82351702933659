import React from "react";
import ClockIcon from "../assets/Svg/ClockIcon";

const DashboardCard = ({title,icon}) => {
  return (
    <div className="bg-white rounded-lg px-6 py-4">
      <h3 className="text-[#5e5873] pb-4 font-semibold">{title}</h3>

      <div className="grid grid-cols-2 items-center gap-y-6">
        {[1, 2, 3, 4, 5].map((item, i) => (
          <>
            <div className=" col-span-1 flex gap-x-3 items-center">
              <div className="bg-[#00a0821f] py-3 px-3 rounded-full w-fit">
               
                {icon}
              </div>
              <div>
                <p className="font-medium text-[#5e5873] text-sm ">DW1378</p>
                <small className="text-[#6e6b7b]">New Order</small>
              </div>
            </div>
            <div>
              <p className="text-sm font-semibold text-[#28c76f] text-right">
                ₹1136.50
              </p>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default DashboardCard;
