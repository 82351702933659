import React, { useState } from "react";
import eng from "../assets/images/en.png";
import SearchIcon from "../assets/Svg/SearchIcon";
import NotificationIcon from "../assets/Svg/NotificationIcon";
import AccountIcon from "../assets/Svg/AccountIcon";

const Header = () => {
  return (
    <div className="bg-slate-200 w-full">
      <div className="py-2 flex gap-x-3 items-center justify-end px-4 ">
   
        <SearchIcon />
        <NotificationIcon />
        <small>Admin</small>
        <div className="bg-[#00a0821f] py-3 px-3 rounded-full">
          <AccountIcon />
        </div>
      </div>
    </div>
  );
};

export default Header;
