import React, { useState } from "react";
import Layout from "../../dashbord/Layout";
import Table from "../../dashbord/Table";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const View = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(5);
  const [viewData, setViewData] = useState(null);
  const [page, setPage] = useState(1);
  const Edit = () => {
    navigate("/quortation");
  };
  const showTotal = (pages, range) => {
    const typeData = [
      {
        id: "1",
        label: "5",
      },
      {
        id: "2",
        label: "15",
      },
      {
        id: "3",
        label: "25",
      },
      {
        id: "4",
        label: "35",
      },
    ];
  };
  const tableObj = [
    {
      dec: "",
      qty: "",
      unitPrice: "",

      total: "0",
    },
    {
      dec: "",
      qty: "",
      unitPrice: "",

      total: "0",
    },
    {
      dec: "",
      qty: "",
      unitPrice: "",

      total: "0",
    },
    {
      dec: "",
      qty: "",
      unitPrice: "",

      total: "0",
    },
  ];
  const Ticketcolumns = [
    {
      title: "Discription",
      dataIndex: "dec",
      key: "dec",

      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            setViewData(record);
            if (viewData) {
              // showViewPage();
            }
          },
        };
      },

      responsive: ["xs", "md"],
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",

      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            setViewData(record);
            if (viewData) {
              // showViewPage();
            }
          },
        };
      },

      responsive: ["xs", "md"],
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",

      responsive: ["xs", "md"],
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",

      responsive: ["xs", "md"],
    },
  ];
  const handleDownloadPage = () => {
    // Get the HTML content of the current page
    const htmlContent = document.documentElement.outerHTML;

    // Create a Blob with the HTML content
    const blob = new Blob([htmlContent], { type: "text/html" });

    // Create a link element and trigger a download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "downloaded_page.html";
    document.body.appendChild(link);
    link.click();

    // Remove the link element from the DOM
    document.body.removeChild(link);
  };
  return (
    <>
      <Layout>
        <div className="p-28">
          <div className="grid grid-cols-2 ">
            <div>
              <h1 className="mt-3 text-2xl text-blue-800 font-medium">Gezte</h1>
              <p className="text-black">
                {" "}
                No. 10/1, V.K. Plaza, Ramamurthi Nagar Main Rd,
                <br /> Annaiah Reddy Layout, Dodda Banaswadi, Bengaluru, <br />
                Karnataka 560043
              </p>
              <p className="text-cyan-600">
                {" "}
                info@gezte.com
                <p className="text-cyan-600"> www.gezte.com</p>
                <p className="text-black"> 9876543210</p>
              </p>
            </div>
            <div>
              <div className="ml-64 ">
                <Button onClick={Edit}>Edit</Button>
              </div>
              <div className="ml-64 ">
                <Button onClick={handleDownloadPage}>Download</Button>
              </div>
              <h2 className="justify-end text-end mr-60 mt-14 font-[DexaBold] text-[#fc2260] text-lg">
                Gezte
              </h2>
            </div>
          </div>
          <div className=" grid grid-cols-3 gap-4">
            <div>
              <h4 className="mt-3 border-b-2 w-40 text-blue-800 text-lg font-medium">
                Bill to
              </h4>

              <h4 className="mt-3 ml-14  ">Gezte</h4>
              <p className="text-black ml-14 ">Gezte</p>
              <p className="text-black ml-14 ">
                {" "}
                No. 10/1, V.K. Plaza, Ramamurthi Nagar Main Rd,
                <br /> Annaiah Reddy Layout, Dodda Banaswadi, Bengaluru, <br />
                Karnataka 560043
              </p>
              <p className="text-black ml-14"> 9876543210</p>
            </div>
            <div>
              <h4 className="mt-3 border-b-2 w-40 text-blue-800 text-lg font-medium">
                Ship to
              </h4>

              <h4 className="mt-3 ml-14   ">Gezte</h4>
              <p className="text-black ml-14 ">Gezte</p>
              <p className="text-black ml-14">
                {" "}
                No. 10/1, V.K. Plaza, Ramamurthi Nagar Main Rd,
                <br /> Annaiah Reddy Layout, Dodda Banaswadi, Bengaluru, <br />
                Karnataka 560043
              </p>
              <p className="text-black ml-14"> 9876543210</p>
            </div>
            <div>
              <p className="text-black"> Quote Date:11/11/11</p>
              <p className="text-black">Valid for: 10 days</p>
            </div>
          </div>
          <div className="mt-5 ">
            <Table
              className="bg-sky-700"
              columns={Ticketcolumns}
              data={tableObj}
            />
          </div>
          <div className="grid grid-cols-2">
            <div>
              <p className="text-black mt-3">Thank You For Your Business!</p>
            </div>
            <div>
              <div className="flex  text-end">
                <p className="text-black ">SubTotal</p>
                <p className="text-black ml-60 ">0.00</p>
              </div>
              <div className="flex  text-end">
                <p className="text-black">Disount</p>
                <p className="text-black ml-60 pl-2">0.00</p>
              </div>
              <div className="flex  text-end">
                <p className="text-black">SubTotal Less Discount</p>
                <p className="text-black ml-32  pl-4">0.00</p>
              </div>
              <div className="flex  text-end">
                <p className="text-black">Tax Rate</p>
                <p className="text-black ml-60 pl-2 ">0.00%</p>
              </div>
              <div className="flex  text-end">
                <p className="text-black">Total Tax</p>
                <p className="text-black ml-60 pl-2">0.00</p>
              </div>
              <div className="flex  text-end border-b-2">
                <p className="text-black">Shipping/Handling</p>
                <p className="text-black ml-40 pl-3">0.00</p>
              </div>
              <div className="flex  text-end  ">
                <p className="text-blue-600 text-2xl font-semibold">
                  Quote Total
                </p>
                <div className="border-b-2 ml-40 w-28 bg-slate-400">
                  <p className="text-black mr-20 text-2xl font-bold  pt-2">$</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-2/3/2">
            <p className=" border-b-2 text-blue-800 text-lg font-medium">
              Terms & Instructions
            </p>
            The Quotation is valid for 10 Days from the Date of Issue Customer
            has to pay 50% of the actual amount to confirm booking. Rest Amount
            to be paid before 2 days of delivery* If you want to cancel the
            order kindly cancel it before 24 hrs.
          </div>
        </div>
      </Layout>
    </>
  );
};

export default View;
