import { Table } from "antd";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

const Tables = ({
  loading,
  columns,
  data,
  onChange,
  pagination,
  size,
  isBordered,
  Title,
  show
}) => {
  const [scroll, setScroll] = useState({ x: undefined, y: 0 });

  // Calculate scroll based on content size
  useEffect(() => {
    const tableBody = document.querySelector(".ant-table-body");
    if (tableBody) {
      const bodyWidth = tableBody.getBoundingClientRect().width;
      const bodyHeight = tableBody.getBoundingClientRect().height;

      let newScroll = { y: 0 };
      if (bodyWidth > window.innerWidth) {
        newScroll = { ...newScroll, x: "max-content" };
      } else {
        newScroll = { ...newScroll, x: undefined };
      }

      if (bodyHeight > 320) {
        newScroll = { ...newScroll, y: 320 };
      }

      setScroll(newScroll);
    }
  }, [data]); // Recalculate when data changes

  return (
    <Table
      loading={loading}
      columns={columns}
      onColumns={show}
      dataSource={data}
      onChange={onChange}
      pagination={pagination}
      rowKey={"id"}
      scroll={scroll}
      size={size}
      bordered={isBordered}
      title={Title && <Title />}
      style={{ overflowX: 'auto', maxWidth: "100%" }}
    />
  );
};

export default Tables;

Tables.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  onChange: PropTypes.func,
  pagination: PropTypes.object,
  size: PropTypes.string,
  isBordered: PropTypes.bool,
  Title: PropTypes.element,
};

Tables.defaultProps = {
  loading: false,
  columns: [],
  data: [],
  onChange: null,
  pagination: {
    pageSize: 5,
    showSizeChanger: true,
    hideOnSinglePage: true,
  },
  size: "large",
  isBordered: false,
  Title: null,
};
