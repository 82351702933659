import React, { useState } from "react";
import Layout from "../../../dashbord/Layout";
import { useNavigate } from "react-router-dom";

const CreateStaff = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <Layout>
        <div className="px-10 bg-[#f6f6f6] h-screen">
          <h3 className="text-[#636363] text-2xl p-2">Create Staff</h3>
          <div className="bg-white px-4 py-4 rounded-md">
            <div className="grid grid-cols-2 gap-x-8 gap-y-4">

            <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Role*</label>
                <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
                  <option selected disabled>
                    Select Role
                  </option>
                  <option>Admin Role</option>
                  <option>Res Role</option>
                  <option>Store Admin</option>
                </select>
              </div>



              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">User Name*</label>
                <input
                  type="text"
                  placeholder="User Name"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Email*</label>
                <input
                  type="text"
                  value="test@gmail.com"
                  placeholder="Email"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Password</label>
                <input
                  type="password"
                  value="test123"
                  placeholder="Password"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

       

              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Status</label>
                <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
                  <option selected disabled>
                   Status
                  </option>
                  <option>Active</option>
                  <option>Inactive</option>
                </select>
              </div>
              <div className="flex flex-col col-span-1"> </div>
            </div>
            <div className="py-4">
              <button className="flex items-center bg-[#5e076a] text-white px-6 py-2 rounded-md text-sm">
                Save
              </button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CreateStaff;
