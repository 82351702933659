import React, { useState } from "react";

import { Link } from "react-router-dom";
import Layout from "../../../dashbord/Layout";

const ActiveRestaurant = () => {
  const [panData, setPanData] = useState({
    number: "",
    file: null,
  });

  const [gstData, setGstData] = useState({
    number: "",
    file: null,
  });

  const [fssaiData, setFssaiData] = useState({
    number: "",
    file: null,
  });

  const handleFileChange = (e, setData) => {
    const file = e.target.files[0];
    setData((prevData) => ({
      ...prevData,
      file: file,
    }));
  };

  const handleDelete = (setData) => {
    setData((prevData) => ({
      ...prevData,
      file: null,
    }));
  };

  const handlePanNumberChange = (e) => {
    const number = e.target.value;
    setPanData((prevData) => ({
      ...prevData,
      number: number,
    }));
  };

  const handleGstNumberChange = (e) => {
    const number = e.target.value;
    setGstData((prevData) => ({
      ...prevData,
      number: number,
    }));
  };

  const handleFssaiNumberChange = (e) => {
    const number = e.target.value;
    setFssaiData((prevData) => ({
      ...prevData,
      number: number,
    }));
  };

  const handleUpload = (setData) => {
    // Add your logic to handle file upload
    console.log(`Upload ${setData.name} Certificate`);
  };

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <form>
          <div className="mb-8">
            <label className="block mb-2 text-gray-600">Pan Certificate</label>
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Enter Pan Number"
                className="border py-2 px-4 mr-4 w-64 rounded-md focus:border-[#fc2260] outline-none focus:outline-none"
                value={panData.number}
                onChange={handlePanNumberChange}
              />
              <input
                type="file"
                onChange={(e) => handleFileChange(e, setPanData)}
                className="mr-4"
              />
              {panData.file && (
                <div className="flex items-center">
                  <img
                    src={URL.createObjectURL(panData.file)}
                    alt="Preview"
                    className="w-20 h-12 mr-4"
                  />
                  <button
                    type="button"
                    onClick={() => handleDelete(setPanData)}
                    className="text-red-500 focus:outline-none"
                  >
                    Delete
                  </button>
                </div>
              )}
              <button
                type="button"
                onClick={() => handleUpload(setPanData)}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
              >
                Upload
              </button>
            </div>
          </div>

          <div className="mb-8">
            <label className="block mb-2 text-gray-600">FSSAI Certificate</label>
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Enter Pan Number"
                className="border py-2 px-4 mr-4 w-64 rounded-md focus:border-[#fc2260] outline-none focus:outline-none"
                value={panData.number}
                onChange={handlePanNumberChange}
              />
              <input
                type="file"
                onChange={(e) => handleFileChange(e, setPanData)}
                className="mr-4"
              />
              {panData.file && (
                <div className="flex items-center">
                  <img
                    src={URL.createObjectURL(panData.file)}
                    alt="Preview"
                    className="w-20 h-12 mr-4"
                  />
                  <button
                    type="button"
                    onClick={() => handleDelete(setPanData)}
                    className="text-red-500 focus:outline-none"
                  >
                    Delete
                  </button>
                </div>
              )}
              <button
                type="button"
                onClick={() => handleUpload(setPanData)}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
              >
                Upload
              </button>
            </div>
          </div>
          <div className="mb-8">
            <label className="block mb-2 text-gray-600">GST Certificate</label>
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Enter Pan Number"
                className="border py-2 px-4 mr-4 w-64 rounded-md focus:border-[#fc2260] outline-none focus:outline-none"
                value={panData.number}
                onChange={handlePanNumberChange}
              />
              <input
                type="file"
                onChange={(e) => handleFileChange(e, setPanData)}
                className="mr-4"
              />
              {panData.file && (
                <div className="flex items-center">
                  <img
                    src={URL.createObjectURL(panData.file)}
                    alt="Preview"
                    className="w-20 h-12 mr-4"
                  />
                  <button
                    type="button"
                    onClick={() => handleDelete(setPanData)}
                    className="text-red-500 focus:outline-none"
                  >
                    Delete
                  </button>
                </div>
              )}
              <button
                type="button"
                onClick={() => handleUpload(setPanData)}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
              >
                Upload
              </button>
            </div>
          </div>

          <div className="mb-8">
            <label className="block mb-2 text-gray-600">
              FSSAI Expiry Date
            </label>
            <input
              type="date"
              className="border py-2 px-4 w-64 rounded-md focus:border-[#fc2260] outline-none focus:outline-none"
            />
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ActiveRestaurant;
