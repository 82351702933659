import React, { useEffect } from "react";
import Layout from "../../dashbord/Layout";
import LineChart from "../../dashbord/LineChart";
import BarChart from "../../dashbord/BarChart";
import RadialBarChart from "../../dashbord/RadialBarChart";
import UserIcon from "../../assets/Svg/UserIcon";
import Driver from "../../assets/Svg/Driver";
import ResturantIcon from "../../assets/Svg/ResturantIcon";
import CreditIcon from "../../assets/Svg/CreditIcon";
import GraphIcon from "../../assets/Svg/GraphIcon";
import DolorIcon from "../../assets/Svg/DolorIcon";
import GiftIcon from "../../assets/Svg/GiftIcon";
import BagIcon from "../../assets/Svg/BagIcon";
import DashboardCard from "../../dashbord/DashboardCard";
import ClockIcon from "../../assets/Svg/ClockIcon";
import ColumnChart from "../../dashbord/ColumnChart";

const Dashboard = () => {
  return (
    <div>
      <Layout>
        <div className="bg-[#f6f6f6] h-fit px-10 py-6 ">
          <div className="grid grid-cols-4 gap-y-6 bg-white p-6 rounded-lg">
            <div className="col-span-1 flex gap-x-3 items-center">
              <div className="bg-[#00a0821f] py-3 px-3 rounded-full w-fit">
                <UserIcon color="#00a082" />
              </div>
              <div>
                <p className="font-semibold text-[#5e5873] ">1328</p>
                <small className="text-[#6e6b7b]">Total Users</small>
              </div>
            </div>

            <div className="col-span-1 flex gap-x-3 items-center">
              <div className="bg-[#00a0821f] py-3 px-3 rounded-full w-fit">
                <Driver color="#28c76f" />
              </div>
              <div>
                <p className="font-semibold text-[#5e5873] ">439</p>
                <small className="text-[#6e6b7b]">
                  Total Delivery Partners
                </small>
              </div>
            </div>

            <div className="col-span-1 flex gap-x-3 items-center">
              <div className="bg-[#00a0821f] py-3 px-3 rounded-full w-fit">
                <ResturantIcon color="#ff9f43" />
              </div>
              <div>
                <p className="font-semibold text-[#5e5873] ">466</p>
                <small className="text-[#6e6b7b]">Total Restaurants</small>
              </div>
            </div>
            <div className="col-span-1 flex gap-x-3 items-center">
              <div className="bg-[#00a0821f] py-3 px-3 rounded-full w-fit">
                <CreditIcon color="#00cfe8" />
              </div>
              <div>
                <p className="font-semibold text-[#5e5873] ">13286</p>
                <small className="text-[#6e6b7b]">Total earnings</small>
              </div>
            </div>

            <div className="col-span-1 flex gap-x-3 items-center">
              <div className="bg-[#00a0821f] py-3 px-3 rounded-full w-fit">
                <GraphIcon color="#ea5455" />
              </div>
              <div>
                <p className="font-semibold text-[#5e5873] ">0</p>
                <small className="text-[#6e6b7b]">Today Earnings</small>
              </div>
            </div>

            <div className="col-span-1 flex gap-x-3 items-center">
              <div className="bg-[#00a0821f] py-3 px-3 rounded-full w-fit">
                <DolorIcon color="#00cfe8" />
              </div>
              <div>
                <p className="font-semibold text-[#5e5873] ">1328</p>
                <small className="text-[#6e6b7b]">Total Admin Comission</small>
              </div>
            </div>

            <div className="col-span-1 flex gap-x-3 items-center">
              <div className="bg-[#00a0821f] py-3 px-3 rounded-full w-fit">
                <GiftIcon color="#28c76f" />
              </div>
              <div>
                <p className="font-semibold text-[#5e5873] ">1328</p>
                <small className="text-[#6e6b7b]">
                  Total Restaurant Comission
                </small>
              </div>
            </div>

            <div className="col-span-1 flex gap-x-3 items-center">
              <div className="bg-[#00a0821f] py-3 px-3 rounded-full w-fit">
                <BagIcon color="#ea5455" />
              </div>
              <div>
                <p className="font-semibold text-[#5e5873] ">1328</p>
                <small className="text-[#6e6b7b]">
                  Total Delivery Boy Comis
                </small>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 mt-8 gap-x-5  ">
            <div className="col-span-5 grid grid-cols-2 gap-x-5 ">
              <div className="bg-white rounded-lg px-3 py-2 col-span-1 w-fit overflow-hidden ">
                <p className="text-sm font-medium text-[#5e5873]">
                  Last 7 days Orders
                </p>
                <BarChart />
              </div>

              <div className="bg-white rounded-lg px-3 py-2 col-span-1 w-fit ">
                <p className="text-sm font-medium text-[#5e5873]">
                  Last 7 days Profit
                </p>
                <LineChart />
              </div>

              <div className=" bg-white  rounded-lg px-3 py-2 col-span-2  w-full mt-6 ">
                <div className="">
                  <h3>Earnings</h3>
                  <small className="text-[#6e6b7b]">This Month</small>
                  <p className="text-[#5e5873] font-medium">₹2530.77</p>
                  <p className=" font-medium text-[#5e5873] text-xs py-4">
                    753.92% more earnings than last month
                  </p>
                  <div className="flex justify-center">
                    <RadialBarChart />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-7 bg-white  ">
              <div className="px-5 py-5">Order Report</div>
              <div className="mt-16">
              <ColumnChart />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-x-6 mt-4 ">
            <div className="col-span-1">
              <DashboardCard
                title="Recent Orders"
                icon={<ClockIcon color="#00cfe8" />}
              />
            </div>

            <div className="col-span-1">
              <DashboardCard
                title="Top Restaurants"
                icon={<ResturantIcon color="#ff9f43" />}
              />
            </div>

            <div className="col-span-1">
              <DashboardCard
                title="Top Users"
                icon={<UserIcon color="#00a082" />}
              />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Dashboard;
