import React, { useState } from "react";
import Layout from "../../../dashbord/Layout";
import { useNavigate } from "react-router-dom";

const AddAdon = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <Layout>
        <div className="px-10 bg-[#f6f6f6] h-screen">
          <h3 className="text-[#472f2f] text-2xl p-2">Add Addon</h3>
          <div className="bg-white px-4 py-4 rounded-md">
            <div className="grid grid-cols-1 gap-x-8 gap-y-4">

            <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Restaurant</label>
                <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
                  <option selected disabled>
                    Select Restaurants
                  </option>
                  <option>Restaurants</option>
                  <option>Restaurants</option>
                  <option>Restaurants</option>
                  <option>Restaurants</option>
                </select>
              </div>

              
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Name</label>
                <input
                  placeholder="Name"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Price</label>
                <input
                  placeholder="Price"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>


            </div>
            <div className="py-4">
              <button className="flex items-center bg-[#5e076a] text-white px-6 py-2 rounded-md text-sm">
                Save
              </button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AddAdon;
