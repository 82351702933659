import React, { useState } from "react";
import Layout from "../../dashbord/Layout";
import { Margin } from "@mui/icons-material";
import Modal from "../../modal/Modal";
import popup from '../../assets/popup.png'

const Quotation = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  return (
    <>
      <Layout>
        <form className="grid grid-cols-2 gap-8 max-w-4xl mx-auto px-8 py-6 bg-white rounded-lg shadow-lg">
          {/* Left Column */}
          <div className="space-y-6">
            <div className="flex items-center">
              <label className="mr-4">
                Add Company Logo
                <span className="block text-sm font-light text-gray-500">
                  (Approved File Type *.png, .jpg, .jpeg, File Size ≤ 2 MB)
                </span>
              </label>
              <input
                type="file"
                // onChange={handleFileChange}
                className="w-60 h-10 border rounded-md px-3 focus:border-[#fc2260] outline-none focus:outline-none"
              />
              {selectedFile && (
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt="Preview"
                  className="w-20 h-10 ml-4 rounded-md border"
                />
              )}
            </div>
            <div>
              <label htmlFor="companyName">Company Name</label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                placeholder="Enter Company Name"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
            <div>
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                placeholder="Enter Address"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter Email"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
            <div>
              <label htmlFor="website">Website</label>
              <input
                type="text"
                id="website"
                name="website"
                placeholder="Enter Website"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
            <div>
              <label htmlFor="mobile">Mobile</label>
              <input
                type="tel"
                id="mobile"
                name="mobile"
                placeholder="Enter Mobile Number"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
            <h2 className="border-b w-60">Bill To</h2>
            <div>
              <label htmlFor="billToAddress">Address</label>
              <input
                type="text"
                id="billToAddress"
                name="billToAddress"
                placeholder="Enter Address"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
            <div>
              <label htmlFor="contactName">Contact Name</label>
              <input
                type="text"
                id="contactName"
                name="contactName"
                placeholder="Enter Contact Name"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
            <div>
              <label htmlFor="clientCompanyName">Client Company Name</label>
              <input
                type="text"
                id="clientCompanyName"
                name="clientCompanyName"
                placeholder="Enter Client Company Name"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
            <div>
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Enter Phone Number"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
            <div>
              <label htmlFor="clientEmail">Client Email</label>
              <input
                type="email"
                id="clientEmail"
                name="clientEmail"
                placeholder="Enter Client Email Address"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-6" style={{marginTop: 63}}>
            <h2 className="border-b text-bold w-100">Ship To</h2>
            <div>
              <label htmlFor="partyName">Party's Name</label>
              <input
                type="text"
                id="partyName"
                name="partyName"
                placeholder="Enter Party's Name"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
            <div>
              <label htmlFor="shipToAddress">Address</label>
              <input
                type="text"
                id="shipToAddress"
                name="shipToAddress"
                placeholder="Enter Address"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
            <div>
              <label htmlFor="shipToClientCompanyName">Client Company Name</label>
              <input
                type="text"
                id="shipToClientCompanyName"
                name="shipToClientCompanyName"
                placeholder="Enter Client Company Name"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
            <div>
              <label htmlFor="shipToPhone">Phone</label>
              <input
                type="tel"
                id="shipToPhone"
                name="shipToPhone"
                placeholder="Enter Phone Number"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
            <div>
              <label htmlFor="shipToEmail">Email</label>
              <input
                type="email"
                id="shipToEmail"
                name="shipToEmail"
                placeholder="Enter Email Address"
                className="w-full border rounded-md px-3 py-2 focus:border-[#fc2260] outline-none focus:outline-none"
              />
            </div>
            <div className="flex justify-end" onClick={openModal}>
              <button className="bg-fuchsia-900 text-white px-4 py-2 rounded-md shadow-md hover:bg-fuchsia-800 focus:outline-none focus:ring-2 focus:ring-fuchsia-900" >
                Submit
              </button>
            </div>
          </div>
        </form>
        {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} >
        <div className="modal-content">
          <img src={popup} alt="Popup" />
        </div>
      </Modal>
      </Layout>
    </>
  );
};

export default Quotation;
