import React, { useState } from "react";
import Nav from "./nav";
import icon from "./../../assets/icons/right-arrow1.png";
import "./../../styles/document.css";
import { Link } from "react-router-dom";
import Head from "./header";
import { FaChevronRight } from "react-icons/fa";

const Document = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  return (
    <div>
      <Nav />
      <div className="mt-5 mx-10">
      <div className="flex justify-between py-8 px-6" style={{boxShadow:"0px 0px 10px rgba(0, 0, 0, 0.2)"}}>  
      <div className="flex items-center gap-x-4">
        <div className="bg-[#ced4da] px-3 py-1 w-fit text-white">1</div>
        <div className="font-semibold"> Business Details</div>
        <div>
        <FaChevronRight />
        </div>
      </div>

      <div className="flex items-center gap-x-4">
        <div className="bg-[#ced4da] px-3 py-1 w-fit text-white">2</div>
        <div className="font-semibold">Business Type</div>
        <div>
        <FaChevronRight />
        </div>
      </div>

      <div className="flex items-center gap-x-4">
        <div className="bg-[#fc2260] px-3 py-1 w-fit text-white">3</div>
        <div className="font-semibold">Document</div>
        <div>
        <FaChevronRight />
        </div>
      </div>
      </div>
        <form>
          <div className="row">
            <label className="title">Pan Certificate</label>
            <div className="row1">
              <input
                type="text"
                placeholder=" Enter Pan Number"
                className=" border py-2 rounded-md px-2 w-[70] h-8 "
              />
              <input
                type="file"
                onChange={handleFileChange}
                className=" w-[70] h-8 mx-3"
              />
              {selectedFile && (
                <div>
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Preview"
                    className="  w-20 h-[7]"
                  />
                </div>
              )}
              <input type="text" />
            </div>
          </div>
          <div className="row">
            <label className="title1">GST</label>
            <div className="row1">
              <input
                type="text"
                placeholder=" Enter GST Number"
                className=" border py-2 rounded-md px-2 ml-20 w-[70] h-8 "
              />
              <input
                type="file"
                onChange={handleFileChange}
                className=" w-[70] h-8 mx-3"
              />
              {selectedFile && (
                <div>
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Preview"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <label className="title">FSSAI Certificate</label>
            <div className="row1">
              <input
                type="text"
                placeholder=" Enter FSSAI Number"
                className=" border py-2 rounded-md px-2 w-[70] h-8 "
              />
              <input
                type="file"
                onChange={handleFileChange}
                className=" w-[70] h-8 mx-3"
              />
              {selectedFile && (
                <div>
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Preview"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <label className="title">FSSAI Expiry Date</label>
            <div className="row1">
              <input
                type="date"
                className=" border py-2 rounded-md px-2 w-[70] h-10 w-70 "
              />
            </div>
          </div>
          <div className="flex justify-between px-3 py-3">
            <Link to="/open">
              <button className=" border py-2 rounded-md px-2 w-3/4 mx-4 h-10 my-4 bg-[#fc2260] font-semibold text-white ">
                Previous
              </button>
            </Link>
            <Link to="/dashboard">
              <button className="btn2 border py-2 rounded-md px-2 w-[70] h-10  w-70  font-semibold  text-white bg-[#fc2260] ">
                Next
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Document;
