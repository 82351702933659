import React, { useState, useEffect } from "react";
import "./../../styles/header.css";
import Modal from "../../modal/Modal";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import axios from "axios";
import { toast } from "react-toastify";

const Header = () => {
  const [createModal, setCreateModal] = useState(false);
  const [createModalOne, setCreateModalOne] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // Clear the local storage key for number when sign-up modal is opened
    if (createModalOne) {
      localStorage.removeItem("number");
    }
  }, [createModalOne]);

  async function handleRegister(data) {
    try {
      const obj = {
        action: "register",
        number: data.number,
        name: data.name,
        email: data.email,
      };
      localStorage.setItem("mobile", data.number);

      const response = await axios.post(
        "https://gezte.com/api/gezte_user_otp.php",
        obj
      );
      const result = await response.data;
      console.log("result", result);
      if (result.message === "Number already exists") {
        toast.warn("Number Already Exist", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        // Open the login modal
        setCreateModal(true);
      } else {
        toast.success("Otp Sent Successfully", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      if (result.otp) {
        setStep(2);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleLoginUser = async () => {
    try {
      const obj = {
        action: "register",
        number: mobileNumber,
      };

      const result = await axios.post(
        "https://gezte.com/api/user_login.php",
        obj
      );
      console.log(mobileNumber);
      if (result.data.message === "Please register first") {
        toast.warn("Number not registered. Please sign up first.");
        setCreateModalOne(true);
      } else {
        toast.success("OTP Sent Successfully");
        localStorage.setItem("number", mobileNumber);
        setStep(2); // Display OTP verification form
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Failed to send OTP. Please try again later.");
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const mob_num = localStorage.getItem("number");
      const obj = {
        action: "verify_otp",
        mobile_number: mob_num,
        otp: otp,
      };
      console.log(obj);
      if (!otp || otp.length < 6) {
        setOtpError("Please Enter 6 digit OTP");
        return;
      }

      const result = await axios.post(
        "https://gezte.com/api/user_login.php",
        obj
      );
      console.log(obj);
      if (result.data.message === "OTP verification successful") {
        toast.success("OTP Verified Successfully");
        navigate("/dashboard"); // Redirect to the desired page after OTP verification
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Failed to verify OTP. Please try again later.");
    }
  };

  const handleVerifyRegisterOTP = async () => {
    try {
      const mob_num = localStorage.getItem("mobile");
      const obj = {
        action: "verify_otp",
        mobile_number: mob_num,
        otp: otp,
      };
      console.log(obj);
      if (!otp || otp.length < 6) {
        setOtpError("Please Enter 6 digit OTP");
        return;
      }

      const result = await axios.post(
        "https://gezte.com/api/user_login.php",
        obj
      );
      console.log(obj);
      if (result.data.message === "OTP verification successful") {
        toast.success("OTP Verified Successfully");
        navigate("/dashboard"); // Redirect to the desired page after OTP verification
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Failed to verify OTP. Please try again later.");
    }
  };

  const handleResendOTP = async () => {
    try {
      const mob_num = localStorage.getItem("number");
      const obj = {
        action: "register",
        number: mob_num,
      };

      const result = await axios.post(
        "https://gezte.com/api/user_login.php",
        obj
      );

      if (result.data.message === "Please register first") {
        toast.warn("Number not registered. Please sign up first.");
      } else {
        toast.success("OTP Resent Successfully");
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      toast.error("Failed to resend OTP. Please try again later.");
    }
  };

  const otpInputStyle = {
    width: "40px",
    height: "40px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    color: "#333",
    fontSize: "16px",
    margin: "4px",
    textAlign: "center",
  };

  return (
    <div className="container">
      <div className="elements">
        <p
          onClick={() => {
            setCreateModal(true);
          }}
        >
          Login
        </p>
        <p
          onClick={() => {
            setCreateModalOne(true);
          }}
        >
          Create Account
        </p>
        <ul></ul>
      </div>
      <Modal isOpen={createModal} onClose={() => setCreateModal(false)}>
        <div className="w-[400px] h-[400px] px-6 py-6">
          <div className="bg-white  rounded-lg">
            {step === 1 && (
              <div className="">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-[#0e0593] font-semibold text-2xl m-0 ">
                      LOGIN
                    </p>
                  </div>

                  <div
                    onClick={() => setCreateModal(false)}
                    className="cursor-pointer m-0 relative bottom-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6 text-black"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </div>

                <div className="my-10">
                  <label className="text-[#6c757d] text-lg m-0 py-2">
                    Enter Phone Number
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Enter.."
                      className="border border-[#0e0593] text-[black] rounded-lg w-full py-3 pl-10 pr-4 "
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                  </div>
                </div>

                <div className="">
                  <button
                    className="bg-[#0e0593] text-[#baf200] w-full py-2 rounded-lg"
                    onClick={handleLoginUser}
                  >
                    Next
                  </button>
                </div>
                <div className="py-2 flex justify-center">
                  <p className="text-[#6c757d] ">
                    Don't have an account ?{" "}
                    <span
                      onClick={() => setCreateModalOne(true)}
                      className="text-[#0e0593] cursor-pointer"
                    >
                      Sign Up
                    </span>
                  </p>
                </div>
              </div>
            )}

            {step === 2 && (
              <div className="">
                <div className="flex justify-center">
                  <p className="text-[#0e0593] font-semibold text-2xl">
                    ENTER OTP
                  </p>
                </div>

                <div className=" flex justify-center">
                  <p className="text-[#6c757d]">
                    Please enter otp to verify your account
                  </p>
                </div>

                <div className="mb-10 mt-5">
                  <label className="text-[#6c757d] text-lg m-0 py-2">
                    Enter 6 digit OTP
                  </label>
                  <div className="flex justify-center">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                      renderInput={(props) => (
                        <input {...props} style={otpInputStyle} />
                      )}
                    />
                  </div>
                </div>

                <div className="">
                  <button
                    className="bg-[#0e0593] text-[#baf200] w-full py-2 rounded-lg"
                    onClick={handleVerifyOTP}
                  >
                    Next
                  </button>
                </div>

                <div className="py-2 flex justify-center">
                  <p className="text-[#6c757d] ">
                    Didn't receive otp ?{" "}
                    <span
                      onClick={() => setCreateModalOne(true)}
                      className="text-[#0e0593] cursor-pointer"
                    >
                      Resend
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal isOpen={createModalOne} onClose={() => setCreateModalOne(false)}>
        <div className="flex flex-col items-center justify-center bg-gray-100">
          <div className="bg-white p-4 rounded-lg shadow-md w-96">
            {step === 1 && (
              <>
                <h1 className="text-3xl font-bold mb-6 text-[#0e0593]">
                  Sign Up
                  <div
                    onClick={() => setCreateModalOne(false)}
                    className="ml-80"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6 text-black"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </h1>
                <div className="flex flex-col space-y-4">
                  <label htmlFor="tel" className="text-lg text-gray-600">
                    Mobile Number:
                  </label>
                  <input
                    type="tel"
                    id="tel"
                    className="border text-[black] rounded-md w-full h-10 px-3 focus:outline-none focus:border-[#0e0593]"
                    placeholder="Enter Your Mobile Number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </div>
                <div className="flex flex-col space-y-4">
                  <label htmlFor="name" className="text-lg text-gray-600">
                    Full Name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="border rounded-md w-full h-10 px-3 focus:outline-none focus:border-[#baf200]"
                    placeholder="Enter Your Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="flex flex-col space-y-4">
                  <label htmlFor="email" className="text-lg text-gray-600">
                    Email Address:
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="border rounded-md w-full h-10 px-3 focus:outline-none focus:border-[#baf200]"
                    placeholder="Enter Your Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="flex mt-6 ">
                  <button
                    onClick={() =>
                      handleRegister({
                        number: mobileNumber,
                        name: name,
                        email: email,
                      })
                    }
                    className="bg-[#0e0593] text-white px-6 py-3 rounded-md hover:bg-[#d41d4c] focus:outline-none"
                  >
                    Send OTP
                  </button>
                </div>
              </>
            )}

            {step === 2 && (
              <>
                <h1 className="text-3xl font-bold mb-6 text-[#fc2260]">
                  Enter OTP
                </h1>
                <div className="flex flex-col space-y-4">
                  <label htmlFor="otp" className="text-lg text-gray-600">
                    Enter OTP:
                  </label>
                  <input
                    type="text"
                    id="otp"
                    className="border rounded-md w-full h-10 px-3 focus:outline-none focus:border-[#baf200]"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>
                <div className="mt-6">
                  <button
                    onClick={handleVerifyRegisterOTP}
                    className="bg-[#0e0593] text-[#baf200] px-6 py-3 rounded-md hover:bg-[#d41d4c] focus:outline-none"
                  >
                    Continue
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Header;
