import React, { useState } from "react";
import Layout from "../../../dashbord/Layout";
import { useNavigate } from "react-router-dom";

const CreateRole = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <Layout>
        <div className="px-10 bg-[#f6f6f6] h-screen">
          <h3 className="text-[#636363] text-2xl p-2">Create Role</h3>
          <div className="bg-white px-4 py-4 rounded-md">
            <div className="grid grid-cols-2 gap-x-8 gap-y-4">
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">New Role Name*</label>
                <input
                  type="text"
                  placeholder="Role Name"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Account Type*</label>
                <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
                  <option selected disabled>
                    Role Type
                  </option>
                  <option>Resturant Previlages</option>
                  <option>Admin Previlages</option>
                </select>
              </div>

              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Duplicate Role of</label>
                <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
                  <option selected disabled>
                    Role Name
                  </option>
                  <option>Super Admin</option>
                  <option>Admin</option>
                </select>
              </div>
              <div className="flex flex-col col-span-1"> </div>

              {/* <div className=" col-span-1 grid grid-cols-3">
                <div className=" flex justify-between col-span-3 bg-[#f3f2f7] px-2 py-2 border-t border-b">
                    <div className="col-span-1">
                    <p className="text-xs font-semibold text-[#6e6b7b] ">NAME</p>
                    </div>
            
                    <div className="col-span-1">
                  <p className="text-xs font-semibold text-[#6e6b7b] ">VIEW</p>
                    </div>
                    <div className="col-span-1"><p className="text-xs font-semibold text-[#6e6b7b] ">EDIT</p> </div>
                </div>
                {
                    ["Order","City","Driver","User","Order","City","Driver","User"].map((item,i)=>(
                        <div className=" flex justify-between col-span-3  px-2 py-2 border-t border-b">
                            <div>
                            <p className="text-xs font-semibold text-[#6e6b7b] ">{item}</p>
                            </div>
                       
                        <div className="bg-black">
                        <input type="checkbox"/>
                        </div>
                      
                        <div>
                        <input type="checkbox"/>
                        </div>
                      </div>
                    ))
                }
              
              </div> */}

              <div className="">
                <table className="w-full bg-[#f3f2f7] border-t border-b">
                  <thead className="">
                    <tr className="">
                      <th className="text-xs font-semibold text-[#6e6b7b] py-3 ">
                        NAME
                      </th>
                      <th className="text-xs font-semibold text-[#6e6b7b] py-3 ">
                        VIEW
                      </th>
                      <th className="text-xs font-semibold text-[#6e6b7b] py-3 ">
                        EDIT
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-[white]">
                  {
                    ["Order","City","Driver","User","Order","City","Driver","User"].map((item,i)=>(
                       <tr className="border-b">
                        <td className="text-xs font-semibold text-[#6e6b7b] text-center py-2">{item}</td>
                        <td className="text-xs font-semibold text-[#6e6b7b] text-center py-2"><input type="checkbox"/></td>
                        <td className="text-xs font-semibold text-[#6e6b7b] text-center py-2"><input type="checkbox"/></td>
                       </tr>
                    ))
                }


                  </tbody>
                </table>
              </div>
            </div>
            <div className="py-4">
              <button className="flex items-center bg-[#5e076a] text-white px-6 py-2 rounded-md text-sm">
                Save
              </button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CreateRole;
