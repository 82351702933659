// import logo from './logo.svg';

import AppRoutes from './components/routes/routes';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";


function App() {
  return (
  
    <div>
       <ToastContainer autoClose={2000} position="top-right" />
      <AppRoutes />
    </div>
  );
}

export default App;
