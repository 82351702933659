import React from "react";
import { FaCheck } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

const ComboCard = ({ img, subimg, title, descp,onClick,val,id }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)" }}
      className="rounded-lg mx-4"
    >
      <div className="w-full h-56">
      <img
        src={`https://gezte.com/api/${img}`}
        className=" relative right-[5px] rounded-t-lg w-[100%] h-[100%] "
        alt="Restaurant Image"
      />
      </div>
    

      <div className="px-2 pb-2 ">
        <div className="flex justify-between items-center gap-x-3 py-2">
          <div className="flex items-center gap-x-3">
            <img src={subimg} alt="Vegetarian Icon" />
            <p >{title}</p>
          </div>
          <div>
            <p
              onClick={onClick}
              className="mr-2 text-[#041a68] font-semibold cursor-pointer "
            >
              Edit
            </p>
            {/* <input type="file" id="fileupload" hidden /> */}
          </div>
        </div>

        <small className=""  style={{
              width: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              color: "#484848",
              fontWeight: "500",
              fontFamily:'Fira Sans',
              marginTop:'4%'
            }}>{descp}</small>

        
        <div className="flex flex-row justify-end py-1 px-2">
          {/* <p className="text-[#041a68] font-medium">
          ₹ 350
          </p> */}
          <button className="border-2 border-[#041a68] px-3 text-[#041a68] rounded-lg font-semibold">{val === id ? "ADDED":"ADD"}</button>

        </div>
      </div>
    </div>
  );
};

export default ComboCard;
