import React, { useEffect, useState } from "react";
import Layout from "../../../dashbord/Layout";
import menuimg from "../../../assets/images/cateringIcon.png";
import ComboCard from "../../../ComboCard/ComboCard";
import vegpackimg from "../../../assets/images/restcardimg.jpg";
import vegimg from "../../../assets/images/vegicon.png";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import nonvegpackimg from "../../../assets/images/nonvegimg.jpg";
import nonvegimg from "../../../assets/images/non-veg.png";
import SideDrawer from "../../../SideDrawer/SideDrawer";
import Noimg from "../../../assets/images/no_image.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import NextArrow from "../../../NextArrow";
import PrevArrow from "../../../PrevArrow";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from "../../../modal/Modal";
import popup from '../../../assets/popup.png'

const ResturantMenu = () => {
  const [val, setVal] = useState();
  const [genval, setGenval] = useState(0);
  const [premval, setPremval] = useState(0);
  const [vegcombval, setVegcombval] = useState(0);
  const [nonvegcombval, setNonvegcombval] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState();
  const [opendrawer, setOpendrawer] = useState(false);
  const [adddrawer, setAdddrawer] = useState(false);
  const [vegdata, setVegdata] = useState([]);
  const [nonvegdata, setNonvegdata] = useState([]);
  const [nonVegPackageId, setNonVegPackageId] = useState();
  const [vegpackageId, setVegPackageId] = useState();
  const [resturantData, setResturantData] = useState([]);
  const [maincourse, setMaincourse] = useState([]);
  const [subvegdata, setSubvegdata] = useState();
  const [subnonvegdata, setSubnonvegdata] = useState([]);
  const [subpackagename, setsubpackagename] = useState([]);
  

  const [cardval, setCardval] = useState();

  ////////////state for post api/////////////
  const [mainpackagename, setmainpackagename] = useState([]);
  const [combotyope, setCombotyope] = useState();

  const [itemsarr, setItemsarr] = useState([]);

  const [itemsofarr, setItemofsarr] = useState([]);

  const [amount, setAmount] = useState();

  const [count, setCount] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 5,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function onSelectFile(e) {
    const file = e.target.files[0]; // Get the first selected file
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Create a URL for the selected file
      setSelectedFile(file); // Update the selectedFile state
      setImageUrl(imageUrl); // Update the imageUrl state with the URL
    }
  }

  const menus = [
    {
      id: 1,
      label: "General Package",
      img: menuimg,
      members: "For Less than 30 people",
    },
    {
      id: 2,
      label: "Premium Package",
      img: menuimg,
      members: "For More than 30 people upto 1000",
    },
  ];

  const generalmenus = [
    {
      id: 1,
      label: "Non Veg",
      img: menuimg,
    },
    {
      id: 2,
      label: "Veg",
      img: menuimg,
    },
  ];

  const premiummenus = [
    {
      id: 1,
      label: "Non Veg",
      img: menuimg,
    },
    {
      id: 2,
      label: "Veg",
      img: menuimg,
    },
  ];

  let arr = [
    {
      sub_package_id: undefined,
      count: undefined,
    },
  ];

  let itemarr = [
    {
      sub_package_id: undefined,
      item_id: undefined,
    },
  ];

  const handleCardChange = (val) => {
    itemarr = [
      {
        sub_package_id: val.sub_package_id,
        item_id: val.item_id,
      },
    ];

    setItemofsarr([
      ...itemsofarr,
      ...itemarr.flat().filter((item) => typeof item === "object"),
    ]);
  };

  const handleinputChange = (e, val) => {
    arr = [
      {
        sub_package_id: val,
        count: e.target.value,
      },
    ];

    setItemsarr([
      ...itemsarr,
      ...arr.flat().filter((item) => typeof item === "object"),
    ]);
  };

  // console.log("itemsarr", itemsarr);
  // console.log("itemsofarr", itemsofarr);

  const getdetails = async () => {
    try {
      const result = await axios.get(
        "https://gezte.com/api/fetch_package_sub_package.php"
      );

      const filternonvegdata = result.data.data.package.filter(
        (val) => val.combo_type === "Non Veg"
      );

      const filtervegdata = result.data.data.package.filter(
        (val) => val.combo_type === "Veg"
      );

      setVegdata(filtervegdata);
      setNonvegdata(filternonvegdata);

      const filtersubnonvegdata = result.data.data.sub_package.filter(
        (val) => val.combo_type === "Non Veg"
      );

      const filtersubvegdata = result.data.data.sub_package.filter(
        (val) => val.combo_type === "Veg"
      );

      const finalresult = filtersubvegdata.map(
        (val, i) => val.sub_package_name
      );
      setSubnonvegdata(filtersubnonvegdata);
      setSubvegdata(finalresult);
    } catch (error) {
      console.log(error);
    }
  };

  const getresturantDetails = async (id) => {
    console.log("vegpackageId", id);
    try {
      const result = await axios.get(
        `https://gezte.com/api/fetch_all_package_details_restaurant.php?package_id=${id}`
      );
      setResturantData(result.data.items_by_sub_package);
      setsubpackagename(result.data.sub_package_names);
      // console.log("Result based on id == >", result.data.items_by_sub_package);
    } catch (error) {
      console.log(error);
    }
  };

  const postData = async () => {
    try {
      const obj = {
        restaurant_id: "58",
        package_type: mainpackagename,
        combo_type: combotyope,
        total_price: amount,
        package_id: vegpackageId,
        sub_package_counts: itemsarr,
        item_counts: itemsofarr,
      };

      console.log("obj", obj);

      const result = await axios.post(
        "https://gezte.com/api/add_restaurant_package.php",
        obj
      );
      console.log("result", result.data);

      if (result.data.message == "Data inserted successfully") {
        return toast.success("Data inserted successfully", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getdetails();
    getresturantDetails();
  }, [vegpackageId]);

  return (
    <>
      <Layout>
        <div className="grid grid-cols-2 gap-x-20 mx-24 mt-10">
          {menus.map((item, i) => (
            <div
              onClick={() => {
                setmainpackagename(item.label);
                setVal(i);
              }}
              key={i}
              className={`flex flex-col justify-center items-center pb-3 rounded-md cursor-pointer ${
                val === i ? "bg-[#041a68]" : "bg-white"
              }`}
              style={{
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                transition: "background-color 0.3s ease",
              }}
            >
              <img src={item.img} className="w-20 h-20" alt={item.label} />

              <p
                className={`text-center font-semibold text-lg ${
                  val === i ? "text-[#baf200]" : "text-[#041a68]"
                }`}
              >
                {item.label}
              </p>

              <p
                className={`text-center font-semibold text-sm ${
                  val === i ? "text-[#baf200]" : "text-[#041a68]"
                }`}
              >
                {item.members}
              </p>
            </div>
          ))}
        </div>

        {/* ///////////////Veg or non veg both general and premium/////////////// */}

        {val === 0 && (
          <div className="grid grid-cols-4 gap-x-20 mx-24 mt-10 justify-center  ">
            {generalmenus.map((item, i) => (
              <div
                onClick={() => {
                  setCombotyope(item.label);
                  setGenval(i);
                }}
                key={i}
                className={`grid justify-center items-center rounded-md py-3 ${
                  genval === i
                    ? item.label === "Veg"
                      ? "bg-green-500"
                      : "bg-red-500"
                    : ""
                }`}
                style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)" }}
              >
                <div className="flex items-center gap-x-2">
                  {genval === i && (
                    <IoMdCheckmarkCircleOutline size={20} color="white" />
                  )}

                  <p
                    className={`text-center font-semibold text-lg ${
                      genval === i ? "text-white" : "text-[#041a68]"
                    } `}
                  >
                    {item.label}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}

        {val === 1 && (
          <div className="grid grid-cols-4 gap-x-20 mx-24 mt-10  ">
            {premiummenus.map((item, i) => (
              <div
                onClick={() => setPremval(i)}
                key={i}
                className={`grid justify-center items-center rounded-md relative left-[280%] py-3 ${
                  premval === i
                    ? item.label === "Veg"
                      ? "bg-green-500"
                      : "bg-red-500"
                    : ""
                }`}
                style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)" }}
              >
                <div className="flex items-center gap-x-2">
                  {premval === i && (
                    <IoMdCheckmarkCircleOutline size={20} color="white" />
                  )}

                  <p
                    className={`text-center font-semibold text-lg ${
                      premval === i ? "text-white" : "text-[#041a68]"
                    } `}
                  >
                    {item.label}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}

        {val === 0 && (
          <div
            style={{
              marginInline: "10%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBlock: "2%",
              alignItems: "center",
            }}
          >
            <div style={{ width: 400 }}>
              <input
                type="text"
                placeholder="Global Search ..."
                style={{
                  border: "1px groove #041a68",
                  paddingBlock: "2%",
                  borderRadius: 10,
                  paddingInline: "2%",
                  outline: "none",
                  width: "100%",
                }}
              />
            </div>
            <div>
              <button
               onClick={openModal}
                style={{
                  border: "none",
                  color: "#baf200",
                  outline: "none",
                  paddingInline: 60,
                  paddingBlock: "6%",
                  borderRadius: 15,
                  backgroundColor: "#041a68",
                }}
              >
                Add
              </button>
            </div>
          </div>
        )}

        {val === 1 && (
          <div
            style={{
              marginInline: "10%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBlock: "2%",
              alignItems: "center",
            }}
          >
            <div style={{ width: 400 }}>
              <input
                type="text"
                placeholder="Global Search ..."
                style={{
                  border: "1px groove #041a68",
                  paddingBlock: "2%",
                  borderRadius: 10,
                  paddingInline: "2%",
                  outline: "none",
                  width: "100%",
                }}
              />
            </div>
            <div>
              <button
                onClick={() => setAdddrawer(!adddrawer)}
                style={{
                  border: "none",
                  color: "#baf200",
                  outline: "none",
                  paddingInline: 60,
                  paddingBlock: "6%",
                  borderRadius: 15,
                  backgroundColor: "#041a68",
                }}
              >
                Add
              </button>
            </div>
          </div>
        )}

        {/* //////////////// General Non Veg Combo's///////////// */}
        {genval === 0 && val === 0 && (
          <div className="grid grid-cols-5 gap-x-10 gap-y-4 mx-24 my-8">
            {nonvegdata.map((item, i) => (
              <div
                onClick={() => setNonvegcombval(i)}
                className={`border-2 p-3 cursor-pointer rounded-md ${
                  nonvegcombval === i ? "bg-[#041a68] text-[#baf200]" : ""
                }`}
              >
                <p onClick={() => setNonVegPackageId(item.id)}>
                  {item.package_name}
                </p>
              </div>
            ))}
          </div>
        )}

        {/* //////////////// General  Veg Combo's///////////// */}
        {genval === 1 && val === 0 && (
          <div className="grid grid-cols-5 gap-x-10 gap-y-4 mx-24 my-8">
            {vegdata.map((item, i) => (
              <div
                onClick={() => {
                  getresturantDetails(item.id);
                  setVegcombval(i);
                  setVegPackageId(item.id);
                }}
                className={`border-2 p-3 cursor-pointer rounded-md ${
                  vegcombval === i ? "bg-[#041a68] text-[#baf200]" : ""
                }`}
              >
                <p>{item.package_name}</p>
              </div>
            ))}
          </div>
        )}

        {genval === 1 && val === 0 && (
          <div className="mb-10">
            {resturantData && (
              <div className="mx-24 flex flex-col gap-y-7 ">
                {subpackagename.map((val, i) => (
                  <div className=" px-4">
                    <div className="my-3 mx-4 flex justify-between">
                      <p className=" font-bold text-xl">
                        {val.sub_package_name}
                      </p>
                      <div className="flex gap-x-5">
                        <p className="font-semibold">
                          No. of items user can select:
                        </p>
                        <input
                          onChange={(e) =>
                            handleinputChange(e, val.sub_package_id)
                          }
                          type="number"
                          placeholder="Enter here .."
                          className="border border-[#041a68] px-2 rounded-lg"
                        />
                      </div>
                    </div>

                    <Slider {...settings}>
                      {resturantData &&
                        resturantData[val.sub_package_name] &&
                        resturantData[val.sub_package_name].map((item, i) => (
                          <div
                            key={i}
                            className="py-2"
                            onClick={() => {
                              setCardval(item.item_id);
                              handleCardChange(item);
                            }}
                          >
                            <ComboCard
                              val={cardval}
                              id={item.item_id}
                              onClick={() => setOpendrawer(!opendrawer)}
                              img={item.image}
                              subimg={vegimg}
                              title={item.item_name}
                              descp={item.description}
                            />
                          </div>
                        ))}
                    </Slider>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* //////////////// Premium Non Veg Combo's///////////// */}
        {premval === 0 && val === 1 && (
          <div className="grid grid-cols-5 gap-y-4 gap-x-10 mx-24 my-8">
            {nonvegdata.map((item, i) => (
              <div
                onClick={() => setNonvegcombval(i)}
                className={`border-2 p-3 cursor-pointer rounded-md ${
                  nonvegcombval === i ? "bg-[#041a68] text-[#baf200]" : ""
                }`}
              >
                <p>{item.package_name}</p>
              </div>
            ))}
          </div>
        )}

        {premval === 0 && val === 1 && (
          <div className="mb-10 ">
            {nonvegcombval === 0 && (
              <div className="mx-24 flex flex-col gap-y-7">
                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Main Course</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>
                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Desert</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>

                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Stater</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>

                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Welcome Drinks</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>

                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Bewrages</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>
                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
              // <div className="grid grid-cols-3 gap-x-8 gap-y-8 mx-24 mt-4">
              //   {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, i) => (
              //     <div>
              //       <ComboCard
              //         onClick={() => setOpendrawer(!opendrawer)}
              //         img={nonvegpackimg}
              //         subimg={nonvegimg}
              //         title="Chicken Tangdi Biryani"
              //         descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
              //       />
              //     </div>
              //   ))}
              // </div>
            )}

            {nonvegcombval === 1 && (
              <div className="mx-24 flex flex-col gap-y-7">
                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Main Course</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>
                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Desert</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>

                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Stater</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>

                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Welcome Drinks</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>

                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Bewrages</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>
                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            )}

            {nonvegcombval === 2 && (
              <div className="mx-24 flex flex-col gap-y-7">
                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Main Course</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>
                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Desert</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>

                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Stater</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>

                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Welcome Drinks</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>

                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Bewrages</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>
                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            )}

            {nonvegcombval === 3 && (
              <div className="mx-24 flex flex-col gap-y-7">
                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Main Course</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>
                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Desert</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>

                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Stater</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>

                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Welcome Drinks</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>

                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className=" px-4">
                  <div className="my-3 mx-4 flex justify-between">
                    <p className=" font-bold text-xl">Bewrages</p>
                    <div className="flex gap-x-5">
                      <p className="font-semibold">
                        No. of items user can select:
                      </p>
                      <input
                        type="number"
                        placeholder="Enter here .."
                        className="border border-[#041a68] px-2 rounded-lg"
                      />
                    </div>
                  </div>
                  <Slider {...settings}>
                    {[1, 2, 3, 4].map((item, i) => (
                      <div key={i} className="py-2">
                        <ComboCard
                          onClick={() => setOpendrawer(!opendrawer)}
                          img={nonvegpackimg}
                          subimg={nonvegimg}
                          title="Chicken Tangdi Biryani"
                          descp="Succulent chicken (boneless) layered over aromatic super-long grain rice,"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            )}
          </div>
        )}

        {/* //////////////// Premium  Veg Combo's///////////// */}
        {premval === 1 && val === 1 && (
          <div className="grid grid-cols-5 gap-y-4 gap-x-10 mx-24 my-8">
            {vegdata.map((item, i) => (
              <div
                onClick={() => {
                  getresturantDetails(item.id);
                  setVegcombval(i);
                }}
                className={`border-2 p-3 cursor-pointer rounded-md ${
                  vegcombval === i ? "bg-[#041a68] text-[#baf200]" : ""
                }`}
              >
                <p>{item.package_name}</p>
              </div>
            ))}
          </div>
        )}

        {premval === 1 && val === 1 && (
          <div className="mb-10">
            {resturantData && (
              <div className="mx-24 flex flex-col gap-y-7 ">
                {subpackagename.map((val, i) => (
                  <div className=" px-4">
                    <div className="my-3 mx-4 flex justify-between">
                      <p className=" font-bold text-xl">
                        {val.sub_package_name}
                      </p>
                      <div className="flex gap-x-5">
                        <p className="font-semibold">
                          No. of items user can select:
                        </p>
                        <input
                          type="number"
                          placeholder="Enter here .."
                          className="border border-[#041a68] px-2 rounded-lg"
                        />
                      </div>
                    </div>

                    <Slider {...settings}>
                      {resturantData &&
                        resturantData[val.sub_package_name] &&
                        resturantData[val.sub_package_name].map((item, i) => (
                          <div key={i} className="py-2">
                            <ComboCard
                              onClick={() => setOpendrawer(!opendrawer)}
                              img={item.image}
                              subimg={vegimg}
                              title={item.item_name}
                              descp={item.description}
                            />
                          </div>
                        ))}
                    </Slider>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {val === 0 && (
          <div
            style={{
              marginInline: "10%",
              display: "flex",
              gap: 25,
              marginBottom: "5%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div className="flex items-center gap-x-3">
              <p>Total Amount</p>
              <input
                onChange={(e) => setAmount(e.target.value)}
                type="number"
                placeholder="Enter Amount here .."
                style={{
                  border: "1px groove #fc2260",
                  borderRadius: 10,
                  paddingInline: 10,
                  paddingBlock: 10,
                }}
              />
            </div>
            <div>
              <button
                onClick={openModal}
                className="bg-[#041a68] text-white px-4 py-2 rounded-lg"
              >
                Submit
              </button>
            </div>
          </div>
        )}

        {val === 1 && (
          <div
            style={{
              marginInline: "10%",
              display: "flex",
              gap: 25,
              marginBottom: "5%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <p>Total Amount</p>
              <input
                type="number"
                placeholder="Enter Amount here .."
                style={{
                  border: "1px groove #fc2260",
                  borderRadius: 10,
                  paddingInline: 10,
                  paddingBlock: 10,
                }}
              />
            </div>
          </div>
        )}

        <div>
          {opendrawer && (
            <SideDrawer
              placement="right"
              width={"28%"}
              headerStyle={{ padding: "0px" }}
              closeIcon={<RxCross2 color="black" size={24} />}
              onClose={() => {
                setOpendrawer(false);
              }}
              closable={true}
              visible={opendrawer}
              bodyStyle={{
                backgroundColor: "",
                padding: "25px",
                paddingRight: "10px",
              }}
            >
              <div style={{ fontFamily: "dexaSemi", fontSize: 20 }}>
                Edit Menu Details
              </div>

              <div className="flex flex-col col-span-1 mb-4">
                <label className="text-[#6e6b7b] py-1" htmlFor="qq">
                  Menu Image
                </label>
                <div className="flex gap-x-10 items-center ">
                  <label htmlFor="fileInput">
                    <div className="bg-[#5e076a] text-white w-fit py-1 px-2 rounded-md">
                      Choose a file
                    </div>
                  </label>

                  <div
                    className={`${
                      imageUrl ? "w-20 h-20" : "w-24 h-20"
                    } rounded-full`}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        className="rounded-full w-full h-full"
                      />
                    ) : (
                      <img src={Noimg} className="rounded-full w-full h-full" />
                    )}
                  </div>
                </div>

                <input
                  onChange={(e) => onSelectFile(e)}
                  type="file"
                  id="fileInput"
                  hidden
                />
              </div>

              <div
                style={{
                  marginBlock: "5%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="flex flex-col col-span-1">
                  <label className="text-[#6e6b7b] py-1">
                    Combo's Veg/Non Veg
                  </label>
                  <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
                    <option selected disabled hidden>
                      Select
                    </option>
                    <option>Veg</option>
                    <option>Non Veg</option>
                  </select>
                </div>

                <div className="flex flex-col col-span-1">
                  <label className="text-[#6e6b7b] py-1">Package Name</label>
                  <input
                    placeholder="Package Name"
                    className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                  />
                </div>

                <div className="flex flex-col col-span-1">
                  <label className="text-[#6e6b7b] py-1">
                    Sub Package Name
                  </label>
                  <input
                    placeholder="Sub Package Name"
                    className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                  />
                </div>

                <div className="flex flex-col col-span-1">
                  <label className="text-[#6e6b7b] py-1">Package Title</label>
                  <input
                    placeholder="Package Title"
                    className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                  />
                </div>

                <div className="flex flex-col col-span-1">
                  <label className="text-[#6e6b7b] py-1">
                    Package Description
                  </label>
                  <textarea
                    placeholder="Package Description"
                    className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                  />
                </div>

                <div className="my-7">
                  <button
                    style={{
                      background: "#5e076a",
                      color: "white",
                      outline: "none",
                      border: "none",
                      paddingInline: "5%",
                      paddingBlock: "3%",
                      borderRadius: 8,
                      width: "100%",
                      fontFamily: "dexaSemi",
                    }}
                  >
                    SAVE DETAILS
                  </button>
                </div>
              </div>
            </SideDrawer>
          )}
        </div>

        <div>
          {adddrawer && (
            <SideDrawer
              placement="right"
              width={"28%"}
              headerStyle={{ padding: "0px" }}
              closeIcon={<RxCross2 color="black" size={24} />}
              onClose={() => {
                setAdddrawer(false);
              }}
              closable={true}
              visible={adddrawer}
              bodyStyle={{
                backgroundColor: "",
                padding: "25px",
                paddingRight: "10px",
              }}
            >
              <div style={{ fontFamily: "dexaSemi", fontSize: 20 }}>
                Add Menu Details
              </div>

              <div className="flex flex-col col-span-1 mb-4">
                <label className="text-[#6e6b7b] py-1" htmlFor="qq">
                  Menu Image
                </label>
                <div className="flex gap-x-10 items-center ">
                  <label htmlFor="fileInputadd">
                    <div className="bg-[#5e076a] text-white w-fit py-1 px-2 rounded-md">
                      Choose a file
                    </div>
                  </label>

                  <div
                    className={`${
                      imageUrl ? "w-20 h-20" : "w-24 h-20"
                    } rounded-full`}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        className="rounded-full w-full h-full"
                      />
                    ) : (
                      <img src={Noimg} className="rounded-full w-full h-full" />
                    )}
                  </div>
                </div>

                <input
                  onChange={(e) => onSelectFile(e)}
                  type="file"
                  id="fileInputadd"
                  hidden
                />
              </div>

              <div
                style={{
                  marginBlock: "5%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="flex flex-col col-span-1">
                  <label className="text-[#6e6b7b] py-1">
                    Combo's Veg/Non Veg
                  </label>
                  <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
                    <option selected disabled hidden>
                      Select
                    </option>
                    <option>Veg</option>
                    <option>Non Veg</option>
                  </select>
                </div>

                <div className="flex flex-col col-span-1">
                  <label className="text-[#6e6b7b] py-1">Package Name</label>
                  <input
                    placeholder="Package Name"
                    className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                  />
                </div>

                <div className="flex flex-col col-span-1">
                  <label className="text-[#6e6b7b] py-1">
                    Sub Package Name
                  </label>
                  <input
                    placeholder="Sub Package Name"
                    className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                  />
                </div>

                <div className="flex flex-col col-span-1">
                  <label className="text-[#6e6b7b] py-1">Package Title</label>
                  <input
                    placeholder="Package Title"
                    className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                  />
                </div>

                <div className="flex flex-col col-span-1">
                  <label className="text-[#6e6b7b] py-1">
                    Package Description
                  </label>
                  <textarea
                    placeholder="Package Description"
                    className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                  />
                </div>

                <div className="my-7">
                  <button
                    style={{
                      background: "#5e076a",
                      color: "white",
                      outline: "none",
                      border: "none",
                      paddingInline: "5%",
                      paddingBlock: "3%",
                      borderRadius: 8,
                      width: "100%",
                      fontFamily: "dexaSemi",
                    }}
                  >
                    SAVE DETAILS
                  </button>
                </div>
              </div>
            </SideDrawer>
          )}
        </div>
        {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} >
        <div className="modal-content">
          <img src={popup} alt="Popup" />
        </div>
      </Modal>
      </Layout>
    </>
  );
};

export default ResturantMenu;
