import React from "react";
import "./../../styles/main.css";
import Header from "../header/header";
import scanner from "../../assets/scanner.png";
import mobile from "../../assets/mobile.png";
import benefit from "../../assets/benefits.png";
import appstore from "../../assets/appstore.png";
import gplay from "../../assets/gplay.png";
import img1 from "../../assets/img01.png";
import img2 from "../../assets/img2.png";
import img3 from "../../assets/img3.png";
import img4 from "../../assets/img4.png";
import img5 from "../../assets/img5.png";
import img6 from "../../assets/img6.png";
import line from "../../assets/line.png"
 
const Main = () => {
  return (
    <div className="App">
      <div className="fullscreen-image-wrapper">
        <div className="logodiv">
          <h1 className="logo">Fesste</h1>
          <Header />
        </div>
        
        <div className="caterers">
          <p>
            <strong>All Caterers</strong>
          </p>
          <p>Register with our Bulk Food Order App today!</p>
        </div>
        <div className="content-wrapper">
          <div className="div1 divsize">
            <p style={{ fontSize: 40 }}>India's Best & Exclusive</p>
            <p style={{ fontSize: 40, marginBottom: 10 }}>
              Bulk Food Order App
            </p>
            <p>Receive Bulk Food Orders Online from our</p>
            <p>Valued Customers</p>
            <div style={{ display: "flex" , marginTop: '20px'}}>
              <div>
                <img
                  src={scanner}
                  style={{ width: 150, height: 150, marginLeft: 10 }}
                  alt="scanner"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "20px",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: "20px" }}>Download the APP NOW!</p>
                <div style={{ display: "flex", flexDirection: 'column' }}>
                  <img src={gplay} style={{marginBottom: '10px'}}/>
                  <img src={appstore} />
                </div>
              </div>
            </div>
          </div>
          <div className="div2 divsize">
            <img src={mobile} style={{ margin: "0", padding: "0px" }} alt="mobile" />
            
          </div>
          <div className="div3 divsize">
            <img src={benefit} alt="benefit" />
          </div>
        </div>
        <div style={{display: 'flex', paddingBottom: '100px'}}>
          <div style={{ display: 'flex' , flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <img src={img1} style={{height: '200px', width: '200px'}} />
            <img src={line} style={{height: '100px', width: '300px'}} />
            <p style={{textAlign: 'center', fontWeight: 'bold', color: '#0e0593', fontSize: '20px'}}>1. Professional <br /> Training</p>
          </div>
          <div style={{display: 'flex' , flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <img src={img2} style={{height: '200px', width: '200px', padding: '10px'}} />
            <img src={line} style={{height: '100px', width: '300px'}} />
            <p style={{textAlign: 'center', fontWeight: 'bold', color: '#0e0593', fontSize: '20px'}}>2. Enhanced <br />Marketing</p>
          </div>
          <div style={{display: 'flex' , flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <img src={img3} style={{height: '200px', width: '200px', padding: '10px'}} />
            <img src={line} style={{height: '100px', width: '300px'}} />
            <p style={{textAlign: 'center', fontWeight: 'bold', color: '#0e0593', fontSize: '20px'}}>3. Dedicated <br />Support</p>
          </div>
          <div style={{display: 'flex' , flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <img src={img4} style={{height: '200px', width: '200px', padding: '10px'}} />
            <img src={line} style={{height: '100px', width: '300px'}} />
            <p style={{textAlign: 'center', fontWeight: 'bold', color: '#0e0593', fontSize: '20px'}}>4. Increased <br />Visibility</p>
          </div>
          <div style={{display: 'flex' , flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <img src={img5} style={{height: '200px', width: '200px', padding: '10px'}} />
            <img src={line} style={{height: '100px', width: '300px'}} />
            <p style={{textAlign: 'center', fontWeight: 'bold', color: '#0e0593', fontSize: '20px'}}>5. Streamlined <br />Payments</p>
          </div>
          <div style={{display: 'flex' , flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <img src={img6} style={{height: '200px', width: '200px', padding: '10px'}} />
            <img src={line} style={{height: '100px', width: '300px'}} />
            <p style={{textAlign: 'center', fontWeight: 'bold', color: '#0e0593', fontSize: '20px'}}>6. Buffet Co-ordination at Venue</p>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default Main;
