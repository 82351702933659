import React, { useState } from "react";
import Layout from "./../../../dashbord/Layout";
import { useNavigate } from "react-router-dom";
import EyeIcon from "./../../../assets/Svg/EyeIcon";
import Table from "./../../../dashbord/Table";
import { Dropdown, Menu } from "antd";
import { CiMenuKebab } from "react-icons/ci";
import EditIcon from "./../../../assets/Svg/EditIcon";
import { FaPlus } from "react-icons/fa6";
import DeleteIcon from "./../../../assets/Svg/DeleteIcon";

const UnitSymbolList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [viewData, setViewData] = useState(null);
  

  const showTotal = (pages, range) => {
    const typeData = [
      {
        id: "1",
        label: "5",
      },
      {
        id: "2",
        label: "15",
      },
      {
        id: "3",
        label: "25",
      },
      {
        id: "4",
        label: "35",
      },
    ];
  };

  const tableObj = [
    {
      srno: "1",
      name:'Ml',
      status: "InActive",
    },
    {
      srno: "2",
      name:'Kg',
      status: "Active",
    },
    {
      srno: "3",
      name:'Ml',
      status: "InActive",
    },
    {
      srno: "4",
      name:'Grm',
      status: "Active",
    },
    {
      srno: "5",
      name:'Ml',
      status: "Active",
    },
  ];
  const menu = (
    <Menu
      items={[
        {
          label: (
            <div
              className="flex items-center pl-[20px]  text-[12px] uppercase"
              onClick={() => {
                if (viewData) {
                  // setView(true);
                }
              }}
            >
              <div className=" font-normal hover:text-blue-400 " type="">
                <i className="ri-eye-line ri-lg mr-2 align-sub"></i>
                view
              </div>
            </div>
          ),
          key: "0",
        },
        {
          label: (
            <div
              className="flex items-center  pl-[20px]  text-[12px] uppercase"
              onClick={() => {
                if (viewData) {
                  // setEdit(true);
                }
              }}
            >
              <div className=" font-normal hover:text-blue-400">
                <i className="ri-pencil-line ri-lg mr-1.5 align-sub"></i>
                edit
              </div>
            </div>
          ),
          key: "2",
        },
        // {
        //   label: (
        //     <div
        //       className="flex items-center  pl-[20px] text-[12px] uppercase"
        //       onClick={() => {
        //         if (viewData) {
        //           Swal.fire({
        //             title: "Are you sure?",
        //             text: "You won't be able to revert this!",
        //             icon: "warning",
        //             showCancelButton: true,
        //             confirmButtonColor: "#3085d6",
        //             cancelButtonColor: "#d33",
        //             confirmButtonText: "Yes, delete it!",
        //           }).then((result) => {
        //             if (result.isConfirmed) {
        //               dispatch(deleteCase(viewData.id));
        //               dispatch(getAllCases);
        //               Swal.fire(
        //                 "Deleted!",
        //                 "Your data has been deleted.",
        //                 "success"
        //               );
        //             }
        //           });
        //         }
        //       }}
        //     >
        //       <div className=" font-normal hover:text-blue-400">
        //         <i className="ri-delete-bin-line ri-lg mr-1.5 align-sub"></i>
        //         delete
        //       </div>
        //     </div>
        //   ),
        //   key: "3",
        // },
      ]}
    />
  );

  const Ticketcolumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },

      responsive: ["xs", "md"],
    },

 

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      sorter: (a, b) => a.status.localeCompare(b.status),
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => (
        <button
          className={`border ${
            record.status === "Active"
              ? "border-green-400 text-green-500"
              : "border-orange-400 text-orange-500"
          }  px-3 py-1 rounded-md`}
        >
          {record.status}
        </button>
      ),
      responsive: ["xs", "md"],
    },

    
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      sorter: (a, b) => a.action - b.action,
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => (
        <div className=" cursor-pointer flex items-center justify-center">
        <EditIcon />
      </div>
      ),
      responsive: ["xs", "md"],
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
    }),
  };

  return (
    <>
      <Layout>
        <div className="px-10 bg-[#f6f6f6] h-screen">
          <h3 className="text-[#636363] text-2xl p-2">Units Symbol</h3>
          <div className="bg-white px-4 py-4 rounded-md">
            <div className="mb-4">
              <button
                onClick={() => navigate("/addunitsymbol")}
                className="flex items-center bg-[#5e076a] text-white px-2 py-1 rounded-md"
              >
                <FaPlus color="white" size={18} /> Add Unit Symbol
              </button>
            </div>
            <Table
              columns={Ticketcolumns}
              data={tableObj}
              scroll={{
                x: 700,
              }}
              pagination={{
                current: page,
                pageSize: pageSize,
                showTotal: showTotal,
                hideOnSinglePage: true,
                pageSizeOptions: [5, 10, 15, 20],
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                },
              }}
           
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default UnitSymbolList;
