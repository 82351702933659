import React, { useState } from "react";
import Layout from "../../../dashbord/Layout";
import { useNavigate } from "react-router-dom";

const AddDocument = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <Layout>
        <div className="px-10 bg-[#f6f6f6] h-screen">
          <h3 className="text-[#636363] text-2xl p-2">Add Document</h3>
          <div className="bg-white px-4 py-4 rounded-md">
            <div className="grid grid-cols-2 gap-x-8 gap-y-4">
              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Document Name</label>
                <input
                  placeholder="Document Name"
                  className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
                />
              </div>

              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Status</label>
                <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
                  <option selected disabled>
                    Select Status
                  </option>
                  <option>Active</option>
                  <option>Inactive</option>
                </select>
              </div>

              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">Document For</label>
                <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
                  <option selected disabled>
                    Document For
                  </option>
                  <option>Delivery Partner</option>
                  <option>Resturant</option>
                </select>
              </div>

              <div className="flex flex-col col-span-1">
                <label className="text-[#6e6b7b] py-1">
                  Expiry Date Needed
                </label>
                <div className="flex flex-row  items-center gap-x-3">
                <div className="flex flex-row mt-2 items-center">
                  <input
                    type="radio"
                    value="Yes"
                    checked={selectedOption === "Yes"}
                    onChange={handleOptionChange}
                  />
                  <label className="text-[#6e6b7b]">Yes</label>
                </div>

                <div className="flex flex-row mt-2 items-center">
                  <input
                    type="radio"
                    value="No"
                    checked={selectedOption === "No"}
                    onChange={handleOptionChange}
                  />
                  <label className="text-[#6e6b7b]">No</label>
                </div>
                </div>
              </div>
            </div>
            <div className="py-4">
              <button
               
                className="flex items-center bg-[#5e076a] text-white px-6 py-2 rounded-md text-sm"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AddDocument;
