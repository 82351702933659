import React, { useState } from "react";
import Layout from "../../../dashbord/Layout";

const Profile = () => {
  const [editMode, setEditMode] = useState(true); // Set initial value to true for editing
  const [formData, setFormData] = useState({
    businessName: "",
    email: "",
    password: "",
    city: "",
    area: "",
    ownerMobile: "",
    businessMobile: "",
    bookingWaitTime: 0,
    geofenceRadius: "",
    address: "",
    businessPhoto: null,
    businessVideo: null,
    aboutBusiness: "",
  });

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      [fileType]: file,
    });
  };

  const handleInputChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handleDaysChange = (event) => {
    const daysValue = parseInt(event.target.value, 10);
    setFormData({
      ...formData,
      bookingWaitTime: isNaN(daysValue) ? 0 : daysValue,
    });
  };

  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  return (
    <Layout>
      <div className="px-10 bg-[#f6f6f6] ">
        <h3 className="text-[#636363] text-2xl p-2">Profile</h3>
        <form className="flex flex-wrap justify-between" style={{ overflowY: "auto" }}>
          {/* Left Column */}
          <div className="w-full md:w-1/2 p-4">
            <div className="form-item">
              <label htmlFor="rname">Business Name</label>
              <div className="input-container">
                <input
                  className="input border py-2 rounded-md px-2 focus:border-[#fc2260] outline-none focus:outline-none"
                  type="text"
                  id="rname"
                  name="businessName"
                  placeholder="Enter Restaurant Name"
                  value={formData.businessName}
                  onChange={(e) => handleInputChange(e, "businessName")}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="form-item">
              <label htmlFor="email">Email</label>
              <div className="input-container">
                <input
                  className="input border py-2 rounded-md px-2 focus:border-[#fc2260] outline-none focus:outline-none "
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={(e) => handleInputChange(e, "email")}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="form-item">
              <label htmlFor="pass">Password</label>
              <div className="input-container">
                <input
                  className="input border py-2 rounded-md px-2 focus:border-[#fc2260] outline-none focus:outline-none"
                  type="password"
                  id="pass"
                  name="password"
                  placeholder="Enter your password"
                  value={formData.password}
                  onChange={(e) => handleInputChange(e, "password")}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="form-item">
              <label htmlFor="ownerMobile">Owner Mobile*</label>
              <div className="input-container">
                <input
                  className="input border py-2 rounded-md px-2 focus:border-[#fc2260] outline-none focus:outline-none"
                  type="tel"
                  id="ownerMobile"
                  name="ownerMobile"
                  placeholder="Enter Owner Mobile Number"
                  value={formData.ownerMobile}
                  onChange={(e) => handleInputChange(e, "ownerMobile")}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="form-item">
              <label htmlFor="businessMobile">Business Mobile*</label>
              <div className="input-container">
                <input
                  className="input border py-2 rounded-md px-2 focus:border-[#fc2260] outline-none focus:outline-none"
                  type="tel"
                  id="businessMobile"
                  name="businessMobile"
                  placeholder="Enter Business Mobile Number"
                  value={formData.businessMobile}
                  onChange={(e) => handleInputChange(e, "businessMobile")}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="form-item">
              <label htmlFor="city">Select City*</label>
              <div className="input-container">
                <input
                  className="input border py-2 rounded-md px-2 focus:border-[#fc2260] outline-none focus:outline-none"
                  type="text"
                  id="city"
                  name="city"
                  placeholder="Select City"
                  value={formData.city}
                  onChange={(e) => handleInputChange(e, "city")}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="form-item">
              <label htmlFor="area">Select Area*</label>
              <div className="input-container">
                <input
                  className="input border py-2 rounded-md px-2 focus:border-[#fc2260] outline-none focus:outline-none"
                  type="text"
                  id="area"
                  name="area"
                  placeholder="Select Area"
                  value={formData.area}
                  onChange={(e) => handleInputChange(e, "area")}
                  disabled={!editMode}
                />
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="w-full md:w-1/2 p-4">
            
            
            <div className="form-item">
              <label htmlFor="bookingWaitTime">Booking Wait Time</label>
              <div className="input-container">
                <input
                  type="number"
                  id="bookingWaitTime"
                  name="bookingWaitTime"
                  placeholder="Enter Booking Wait Time"
                  className="input border py-2 rounded-md px-2 focus:border-[#fc2260] outline-none focus:outline-none w-1/2"
                  min="0"
                  max="90"
                  value={formData.bookingWaitTime}
                  onChange={(e) => handleInputChange(e, "bookingWaitTime")}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="form-item">
              <label htmlFor="geofenceRadius">Geofence Radius</label>
              <div className="input-container">
                <input
                  type="text"
                  id="geofenceRadius"
                  name="geofenceRadius"
                  className="input border py-2 rounded-md px-2 focus:border-[#fc2260] outline-none focus:outline-none"
                  placeholder="Enter Geofence Radius"
                  value={formData.geofenceRadius}
                  onChange={(e) => handleInputChange(e, "geofenceRadius")}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="form-item">
              <label htmlFor="address">Address*</label>
              <div className="input-container">
                <input
                  className="input border py-2 rounded-md px-2 focus:border-[#fc2260] outline-none focus:outline-none"
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Enter Address"
                  value={formData.address}
                  onChange={(e) => handleInputChange(e, "address")}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="form-item">
              <label htmlFor="businessPhoto">Add Business Photo</label>
              <div className="input-container">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, "businessPhoto")}
                  className="border py-2 rounded-md px-2 focus:border-[#fc2260] outline-none focus:outline-none"
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="form-item">
              <label htmlFor="businessVideo">Add Business Video</label>
              <div className="input-container">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, "businessVideo")}
                  className="border py-2 rounded-md px-2 focus:border-[#fc2260] outline-none focus:outline-none"
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="form-item">
              <label htmlFor="aboutBusiness">About Business</label>
              <div className="input-container">
                <textarea
                  name="aboutBusiness"
                  rows="5"
                  cols="40"
                  className="input border py-2 rounded-md px-2 focus:border-[#fc2260] outline-none focus:outline-none"
                  placeholder="Write Something About Your Business"
                  value={formData.aboutBusiness}
                  onChange={(e) => handleInputChange(e, "aboutBusiness")}
                  disabled={!editMode}
                />
              </div>
            </div>
          </div>

          {/* Edit Button */}
          <div className="w-full px-4">
            <div className="flex justify-center">
              <button
                type="button"
                onClick={handleEditClick}
                className={`button border py-2 w-80 rounded-md px-5 my-2 text-white focus:border-[#fc2260] outline-none focus:outline-none bg-${editMode ? 'blue' : 'green'}-500`}
              >
                {editMode ? "Edit" : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Profile;
