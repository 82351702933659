import React, { useState } from "react";
import Layout from "../../../dashbord/Layout";
import { useNavigate } from "react-router-dom";
import { Input, Button, Space, Table as AntTable } from "antd";
import { CiSearch } from "react-icons/ci";
import Modal from "../../../modal/Modal";
import popup from '../../../assets/popup.png'

const ResturantReport = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const tableData = [
    {
      key: "1",
      resturant: "Pizza.com - Italia",
      email: "********",
      phoneNumber: "*******",
      ratings: "*******",
      address: "Damrak 87, 1012 LP Amsterdam, Netherlands",
      city: "Bangalore",
      area: "Kormangala",
      totalOrder: "₹0.00",
      totalResturantEarning: "₹1057.22",
      pendingPayout: "₹000",
      payoutCompleted: "₹0.00",
    },
    {
      srno: "2",
      resturant: "Pizza.com - Italia",
      email: "********",
      phoneno:"*******",
      ratings:"*******",
      Address:"Damrak 87, 1012 LP Amsterdam, Netherlands",
      city:"Bangalore",
      Area:"Kormangala",
      totalorder:"₹0.00",
      ttlresturanterng:"₹1057.22",
      pendingpyout:"₹000",
      payoutcmpltd:"₹0.00"
    },
    {
      srno: "3",
       resturant: "Pizza.com - Italia",
      email: "********",
      phoneno:"*******",
      ratings:"*******",
      Address:"Damrak 87, 1012 LP Amsterdam, Netherlands",
      city:"Bangalore",
      Area:"Kormangala",
      totalorder:"₹0.00",
      ttlresturanterng:"₹1057.22",
      pendingpyout:"₹000",
      payoutcmpltd:"₹0.00"
    },
    {
      srno: "4",
      resturant: "Pizza.com - Italia",
      email: "********",
      phoneno:"*******",
      ratings:"*******",
      Address:"Damrak 87, 1012 LP Amsterdam, Netherlands",
      city:"Bangalore",
      Area:"Kormangala",
      totalorder:"₹0.00",
      ttlresturanterng:"₹1057.22",
      pendingpyout:"₹000",
      payoutcmpltd:"₹0.00"
    },
    {
      srno: "5",
       resturant: "Pizza.com - Italia",
      email: "********",
      phoneno:"*******",
      ratings:"*******",
      Address:"Damrak 87, 1012 LP Amsterdam, Netherlands",
      city:"Bangalore",
      Area:"Kormangala",
      totalorder:"₹0.00",
      ttlresturanterng:"₹1057.22",
      pendingpyout:"₹000",
      payoutcmpltd:"₹0.00"
    },
  ];

  const columns = [
    {
      title: "Resturant",
      dataIndex: "resturant",
      key: "resturant",
      width: 200,
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 150,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 150,
    },
    {
      title: "Ratings",
      dataIndex: "ratings",
      key: "ratings",
      width: 100,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: 150,
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
      width: 150,
    },
    {
      title: "Total Order",
      dataIndex: "totalOrder",
      key: "totalOrder",
      width: 120,
    },
    {
      title: "Total Resturant Earning",
      dataIndex: "totalResturantEarning",
      key: "totalResturantEarning",
      width: 180,
    },
    {
      title: "Pending Payout",
      dataIndex: "pendingPayout",
      key: "pendingPayout",
      width: 150,
    },
    {
      title: "Payout Completed",
      dataIndex: "payoutCompleted",
      key: "payoutCompleted",
      width: 150,
    },
  ];

  const handleExport = () => {
    // Handle export functionality
  };

  const handleDateFilter = () => {
    // Handle date filter functionality
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const handleSearchReset = () => {
    setSearchText("");
  };

  const searchInput = (
    <Input
      placeholder="Search..."
      value={searchText}
      onChange={handleSearchChange}
      onPressEnter={() => handleSearch()}
      style={{ width: 200 }}
    />
  );

  const searchDropdown = (
    <Space>
      <Button
        type="primary"
        onClick={() => handleSearch()}
        icon={<CiSearch />}
        size="small"
        style={{ width: 90 }}
      >
        Search
      </Button>
      <Button onClick={openModal} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </Space>
  );

  const tablePagination = {
    pageSizeOptions: ["5", "10", "15", "20"],
    showTotal: (total) => `Total ${total} items`,
  };

  return (
    <Layout>
      <div className="px-10 bg-[#f6f6f6] h-screen">
        <h3 className="text-[#636363] text-2xl p-2">Restaurant Report</h3>
        <div className="bg-white px-4 py-4 rounded-md">
          <div className="mb-4 flex flex-row justify-between">
            <div className="flex gap-x-5">
              <Button
                onClick={openModal}
                className="flex items-center bg-[#5e076a] text-white px-4 py-1 rounded-md"
              >
                Export
              </Button>

              <Button
                onClick={openModal}
                className="flex items-center bg-[#5e076a] text-white px-4 py-1 rounded-md"
              >
                Date Filter
              </Button>
            </div>

            <div className="border border-[#636363] flex items-center py-1 rounded-md px-2 gap-x-2">
              {searchInput}
              {searchDropdown}
            </div>
          </div>

          <AntTable
            columns={columns}
            dataSource={tableData}
            scroll={{ x: 1500 }}
            pagination={tablePagination}
            bordered
          />
        </div>
      </div>
      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} >
        <div className="modal-content">
          <img src={popup} alt="Popup" />
        </div>
      </Modal>
    </Layout>
  );
};

export default ResturantReport;
