import React, { useState } from "react";
import Layout from "../../../dashbord/Layout";
import { useNavigate } from "react-router-dom";
import EyeIcon from "./../../../assets/Svg/EyeIcon";
import Table from "../../../dashbord/Table";
import { Dropdown, Menu } from "antd";
import { CiMenuKebab } from "react-icons/ci";
import EditIcon from "./../../../assets/Svg/EditIcon";
import { FaPlus } from "react-icons/fa6";
import DeleteIcon from "./../../../assets/Svg/DeleteIcon";
import { CiSearch } from "react-icons/ci";
import List from "./../../../assets/Svg/List";

const OrderReport = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [viewData, setViewData] = useState(null);

  const showTotal = (pages, range) => {
    const typeData = [
      {
        id: "1",
        label: "5",
      },
      {
        id: "2",
        label: "15",
      },
      {
        id: "3",
        label: "25",
      },
      {
        id: "4",
        label: "35",
      },
    ];
  };

  const tableObj = [
    {
      srno: "1",
     orderId:"DW015",
     date:"2022-09-08 19:17:09",
     customername:"Ranjith Bytesflow",
     customerphno:"9874563210",
     drivername:"Plahanov",
     driverphno:"987463210",
     resturant: "Pizza.com - Italia",
     amount:"₹0.00",
     tax:"₹0.00",
     offer:"₹0.00",
     admincmsn:"₹0.00",
     drivercmns:"₹0.00",
     resturntcmsn:"₹0.00",
     status:"Delivery Boy Assigned"



    },
    {
      srno: "2",

      orderId:"DW015",
      date:"2022-09-08 19:17:09",
      customername:"Ranjith Bytesflow",
      customerphno:"9874563210",
      drivername:"Plahanov",
      driverphno:"987463210",
      resturant: "Pizza.com - Italia",
      amount:"₹0.00",
      tax:"₹0.00",
      offer:"₹0.00",
      admincmsn:"₹0.00",
      drivercmns:"₹0.00",
      resturntcmsn:"₹0.00",
      status:"Delivery Boy Assigned"
    },
    {
      srno: "3",
      orderId:"DW015",
      date:"2022-09-08 19:17:09",
      customername:"Ranjith Bytesflow",
      customerphno:"9874563210",
      drivername:"Plahanov",
      driverphno:"987463210",
      resturant: "Pizza.com - Italia",
      amount:"₹0.00",
      tax:"₹0.00",
      offer:"₹0.00",
      admincmsn:"₹0.00",
      drivercmns:"₹0.00",
      resturntcmsn:"₹0.00",
      status:"Delivery Boy Assigned"
    },
    {
      srno: "4",
      orderId:"DW015",
      date:"2022-09-08 19:17:09",
      customername:"Ranjith Bytesflow",
      customerphno:"9874563210",
      drivername:"Plahanov",
      driverphno:"987463210",
      resturant: "Pizza.com - Italia",
      amount:"₹0.00",
      tax:"₹0.00",
      offer:"₹0.00",
      admincmsn:"₹0.00",
      drivercmns:"₹0.00",
      resturntcmsn:"₹0.00",
      status:"Delivery Boy Assigned"
    },
    {
      srno: "5",
      orderId:"DW015",
      date:"2022-09-08 19:17:09",
      customername:"Ranjith Bytesflow",
      customerphno:"9874563210",
      drivername:"Plahanov",
      driverphno:"987463210",
      resturant: "Pizza.com - Italia",
      amount:"₹0.00",
      tax:"₹0.00",
      offer:"₹0.00",
      admincmsn:"₹0.00",
      drivercmns:"₹0.00",
      resturntcmsn:"₹0.00",
      status:"Delivery Boy Assigned"
    },
  ];

  const Ticketcolumns = [
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",

      sorter: (a, b) => a.orderId.localeCompare(b.orderId),
      ellipsis: {
        showTitle: false,
      },

      responsive: ["xs", "md"],
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",

      sorter: (a, b) => a.date.localeCompare(b.date),
      ellipsis: {
        showTitle: false,
      },

      responsive: ["xs", "md"],
    },

    {
        title: "Customer Name",
        dataIndex: "customername",
        key: "customername",
  
        sorter: (a, b) => a.customername.localeCompare(b.customername),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Customer Phone Number",
        dataIndex: "customerphno",
        key: "customerphno",
  
        sorter: (a, b) => a.customerphno.localeCompare(b.customerphno),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Delivery Partner Name",
        dataIndex: "drivername",
        key: "drivername",
  
        sorter: (a, b) => a.drivername.localeCompare(b.drivername),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Partner Phone number",
        dataIndex: "driverphno",
        key: "driverphno",
  
        sorter: (a, b) => a.driverphno.localeCompare(b.driverphno),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Resturant",
        dataIndex: "resturant",
        key: "resturant",
  
        sorter: (a, b) => a.resturant.localeCompare(b.resturant),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
  
        sorter: (a, b) => a.amount.localeCompare(b.amount),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Tax",
        dataIndex: "tax",
        key: "tax",
  
        sorter: (a, b) => a.tax.localeCompare(b.tax),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Offer",
        dataIndex: "offer",
        key: "offer",
  
        sorter: (a, b) => a.offer.localeCompare(b.offer),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Admin Commission",
        dataIndex: "admincmsn",
        key: "admincmsn",
  
        sorter: (a, b) => a.admincmsn.localeCompare(b.admincmsn),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },

      {
        title: "Driver Commission",
        dataIndex: "drivercmns",
        key: "drivercmns",
  
        sorter: (a, b) => a.drivercmns.localeCompare(b.drivercmns),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },
      {
        title: "Resturant Commission",
        dataIndex: "resturntcmsn",
        key: "resturntcmsn",
  
        sorter: (a, b) => a.resturntcmsn.localeCompare(b.resturntcmsn),
        ellipsis: {
          showTitle: false,
        },
  
        responsive: ["xs", "md"],
      },

      {
        title: "Status",
        dataIndex: "status",
        key: "status",
  
        sorter: (a, b) => a.status.localeCompare(b.status),
        ellipsis: {
          showTitle: false,
        },

        render: (_, record) => (
            <div className=" cursor-pointer flex items-center justify-center text-white bg-[#5e076a] px-2">
      {record.status}
          </div>
          ),
        responsive: ["xs", "md"],
      },
    
    
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
    }),
  };

  return (
    <>
      <Layout>
        <div className="px-10 bg-[#f6f6f6] h-screen">
          <h3 className="text-[#636363] text-2xl p-2">Order Report</h3>
          <div className="bg-white px-4 py-4 rounded-md">
            <div className="mb-4 flex flex-row justify-between">
                <div className="flex gap-x-5">
                <button
                onClick={() => navigate("/addpromocode")}
                className="flex items-center bg-[#5e076a] text-white px-4 py-1 rounded-md"
              >
                Export
              </button>

              <button
                onClick={() => navigate("/addpromocode")}
                className="flex items-center bg-[#5e076a] text-white px-4 py-1 rounded-md"
              >
              Date Filter
              </button>
                </div>
   

              <div className="border border-[#636363] flex items-center py-1 rounded-md px-2 gap-x-2">
                <CiSearch size={20} />
                <input placeholder="Search..." className=" outline-none " />
              </div>
            </div>

            <Table
              columns={Ticketcolumns}
              data={tableObj}
              scroll={{
                x: 700,
              }}
              pagination={{
                current: page,
                pageSize: pageSize,
                showTotal: showTotal,
                hideOnSinglePage: true,
                pageSizeOptions: [5, 10, 15, 20],
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default OrderReport;
