import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = () => {
  // Sample data for the last 7 days' orders
  const ordersData = [15, 22, 30, 18, 25, 20, 28];

  // Generate labels for the last 7 days
  const today = new Date();
  const last7DaysLabels = Array.from({ length: 7 }, (_, i) => {
    const day = new Date(today);
    day.setDate(today.getDate() - i);
    return day.toLocaleDateString('en-US', { weekday: 'short' });
  }).reverse();

  const chartOptions = {
    chart: {
      id: 'bar-chart',
      type: 'bar',
      toolbar: {
        show: false, // Set to false to hide the toolbar with zoom options
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '10%', // Adjust the width of the bars as needed
      },
    },
    xaxis: {
      categories: last7DaysLabels,
      labels: {
        show: false, // Set to false to hide y-axis labels
      },
    },
    yaxis: {
        labels: {
          show: false, // Set to false to hide y-axis labels
        },
        
      },
  };

  const chartSeries = [
    {
      name: 'Orders',
      data: ordersData,
    },
  ];

  return (
    <div>
      <Chart options={chartOptions} series={chartSeries} type="bar" height={150} width={200} />
    </div>
  );
};

export default BarChart;
