import React, { useState } from "react";
// import "./../../styles/formPage.css";
import icon from "./../../assets/icons/right-arrow1.png";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const FormPage = () => {

  const navigate = useNavigate()

  const [selectedCity, setSelectedCity] = useState("");
  const [selectedDays, setSelectedDays] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleDaysChange = (event) => {
    const daysValue = parseInt(event.target.value, 10);
    setSelectedDays(isNaN(daysValue) ? 0 : daysValue);
  };

  const handleSetRange = () => {
    console.log("Selected City:", selectedCity);
    console.log("Selected Days:", selectedDays);
    // Add your logic here to handle the selected city and days
  };

  return (
    <div className="px-14 bg-[white] h-fit mt-8">
      <div className="flex justify-between py-8 px-6" style={{boxShadow:"0px 0px 10px rgba(0, 0, 0, 0.2)"}}>  
      <div className="flex items-center gap-x-4">
        <div className="bg-[#fc2260] px-3 py-1 w-fit text-white">1</div>
        <div className="font-semibold"> Business Details</div>
        <div>
        <FaChevronRight />
        </div>
      </div>

      <div className="flex items-center gap-x-4">
        <div className="bg-[#ced4da] px-3 py-1 w-fit text-white">2</div>
        <div className="font-semibold">Business Type</div>
        <div>
        <FaChevronRight />
        </div>
      </div>

      <div className="flex items-center gap-x-4">
        <div className="bg-[#ced4da] px-3 py-1 w-fit text-white">3</div>
        <div className="font-semibold">Document</div>
        <div>
        <FaChevronRight />
        </div>
      </div>
      </div>



    <div className="bg-white px-4 py-4 rounded-md my-10  " style={{boxShadow:"0px 0px 10px rgba(0, 0, 0, 0.2)"}}>
      <div className="grid grid-cols-2 gap-x-8 gap-y-4">
        <div className="flex flex-col col-span-1">
          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">
              Restaurant Name*
            </label>
            <input
              type="text"
              placeholder="Name"
              className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">Enter Email*</label>
            <input
              type="text"
              placeholder="Enter Email"
              className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">Password</label>
            <input
              type="text"
              placeholder="Enter Password"
              className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">Select City*</label>
            <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
              <option selected disabled>
                Select City
              </option>
              <option>Bangalore</option>
              <option>Mysore</option>
            </select>
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">Select Area*</label>
            <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
              <option selected disabled>
                Select Area
              </option>
              <option>Bangalore</option>
              <option>Mysore</option>
            </select>
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">Mobile Number*</label>
            <input
              type="text"
              placeholder="Enter Number"
              className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">Contact Number*</label>
            <input
              type="text"
              placeholder="Enter Contact"
              className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">
              Estimated Delivery Time(Mins)*
            </label>
            <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
              <option selected disabled>
                Select
              </option>
              <option>15-20</option>
              <option>2-30</option>
              <option>30-40</option>
              <option>40-50</option>
            </select>
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">
              Packaging Charge(%)*
            </label>
            <input
              type="text"
              placeholder="Enter Packing Charge"
              className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">Geofence Radius</label>
            <input
              type="text"
              placeholder="800"
              className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">
              Delivering Zones
            </label>
            <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
              <option selected disabled>
                Delivering Zones
              </option>
              <option>Zone 1</option>
              <option>Zone 2</option>
              <option>Zone 3</option>
              <option>Zone 4</option>
            </select>
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">Tags</label>
            <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
              <option selected disabled>
                Select Tag
              </option>
              <option>Hot</option>
            </select>
          </div>

    
        </div>

        <div className="flex flex-col col-span-1">
          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">Address*</label>
            <input
              type="text"
              placeholder="Enter Address"
              className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">
              Admin Commission %
            </label>
            <input
              type="text"
              placeholder="10"
              className="border border-[#22292f1a]  text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm"
            />
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">Brand</label>
            <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
              <option selected disabled>
                Brand
              </option>
              <option>BBC</option>
              <option>MVC</option>
              <option>MNC</option>
              <option>BBC</option>
            </select>
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">Status*</label>
            <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
              <option selected disabled>
                Select Status
              </option>
              <option>Active</option>
              <option>Inactive</option>
            </select>
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">Free Delivery*</label>
            <select className="border border-[#22292f1a] text-[#6e6b7b] outline-none py-2 px-2 rounded-md text-sm">
              <option selected disabled>
                Select
              </option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">Cuisines*</label>
            <div className="ml-4">  
            <div className="flex items-center gap-x-3">
              <input type="checkbox" />
              <p className="text-[#6e6b7b] py-1">Kebab</p>
            </div>

            <div className="flex items-center gap-x-3">
              <input type="checkbox" />
              <p className="text-[#6e6b7b] py-1">Asian</p>
            </div>

            <div className="flex items-center gap-x-3">
              <input type="checkbox" />
              <p className="text-[#6e6b7b] py-1">Chinese</p>
            </div>

            <div className="flex items-center gap-x-3">
              <input type="checkbox" />
              <p className="text-[#6e6b7b] py-1">Continental</p>
            </div>

            <div className="flex items-center gap-x-3">
              <input type="checkbox" />
              <p className="text-[#6e6b7b] py-1">South Indian</p>
            </div>

            <div className="flex items-center gap-x-3">
              <input type="checkbox" />
              <p className="text-[#6e6b7b] py-1">Italian</p>
            </div>

            <div className="flex items-center gap-x-3">
              <input type="checkbox" />
              <p className="text-[#6e6b7b] py-1">Burger</p>
            </div>

            
            <div className="flex items-center gap-x-3">
              <input type="checkbox" />
              <p className="text-[#6e6b7b] py-1">Mexican</p>
            </div>
            </div>
          </div>

          <div className="flex flex-col col-span-1">
            <label className="text-[#6e6b7b] py-1">Delivery Type*</label>
            <div className="ml-4">  
            <div className="flex items-center gap-x-3">
              <input type="checkbox" />
              <p className="text-[#6e6b7b] py-1">Home Delivery</p>
            </div>

            <div className="flex items-center gap-x-3">
              <input type="checkbox" />
              <p className="text-[#6e6b7b] py-1">Pickup</p>
            </div>

            <div className="flex items-center gap-x-3">
              <input type="checkbox" className="!focus:outline-none"/>
              <p className="text-[#6e6b7b] py-1">Dining</p>
            </div>
            </div>
          </div>

        </div>
      </div>

      <div className="py-4 flex justify-end">
        <button onClick={()=>navigate('/open')} className="flex items-center bg-[#fc2260] text-white px-6 py-2 rounded-md text-sm">
          Next
        </button>
      </div>
    </div>
  </div>
  
  );
};

export default FormPage;
